import React, { useState, useEffect, createContext, useContext } from 'react';
import {
  Card, Stack, Box, Container,
  Typography, Button, TextField, Input
} from '@mui/material';
import Page from '../components/Page';
import { Context, FormatDate } from "src/Context";
import BasicDateRangePicker from 'src/components/table_users/BasicDateRangePicker';
import {ApiServer} from ".././settings"
import { faker } from '@faker-js/faker';
import getDataJson from 'src/components/common_components/getDataJson';
import InputWithButton from 'src/components/common_components/inputWithButton';
import FileInfo from 'src/components/control/FileInfo';
import { getVideofileById } from './FileControl';
import { show } from './FileControl';

export function searchVideofile(searchstr) {
    const url = ApiServer.api + "/api/api_videofile.php"+`?searchstr=${searchstr}`+"&act=accept";
    return url;
}

function FileAcceptComponent(props) {
    const fileInfo = props.fileInfo;
    const setResponse = props.setResponse;

return (
    <div>
                <FileInfo key={faker.datatype.uuid()} videofiles_id={fileInfo.videofiles_id} verify={fileInfo.verify} line_id={fileInfo.line_id} info={fileInfo.info} create_date={fileInfo.create_date}
                 time_length={fileInfo.time_length}  start_converted_time={fileInfo.start_converted_time}  files_status={fileInfo.files_status}  exported_filepath={fileInfo.exported_filepath}/>
                     
            <Box mt={1} mb={5}>
            <Stack direction="row" spacing={2}  alignItems="center" justifyContent="space-around" >

            <Button
            variant="contained"
            color="error"
            onClick = {
                () => {
                    //console.log('task ok' + inputText);
                    const url = ApiServer.api + "/api/api_videofile.php?act=set_accepted&accepted=1&videofiles_id=" + fileInfo.videofiles_id;
                    getDataJson(url, setResponse);  
                }
            }
            >
                ОТМЕТИТЬ КАК ПРИНЯТЫЙ
            </Button>
            <Button 
             variant="contained"
             color="error"
            onClick = {
                () => {
                    //console.log('task error' + inputText);
                    const url = ApiServer.api + "/api/api_videofile.php?act=set_accepted&accepted=0&videofiles_id=" + fileInfo.videofiles_id;
                    getDataJson(url, setResponse);  
                }
            }

            
            >
                ОТМЕТИТЬ КАК НЕПРИНЯТЫЙ
            </Button>
            </Stack>
            </Box>
            </div>

)
}

export default function Accept() {
  console.log("cityid", Context.city_id);
  const dates = {createDate: FormatDate(new Date()), exportDate: FormatDate(new Date())};
  const [filesInfo, setFilesInfo] = useState([]);
  const [response, setResponse] = useState({response: "без изменений"});

  return (
    <Page title="Accept">
      <div style={{paddingLeft: '220px'}}>
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> */}
          <Typography variant="h4" gutterBottom>
            Принятие файлов для города {Context.city}
          </Typography>

        {/* </Stack> */}
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> */}
        <Typography variant="h6" gutterBottom>
            Состояние: {response.response}
          </Typography>
        {/* </Stack> */}
        <Card sx={{p: '10px'}}>
        <Typography variant="h5" gutterBottom>
            Принять файлы
        </Typography>
        Проверено до даты съемки: <BasicDateRangePicker data={dates} field="createDate" onSelect={() => {console.log(dates);}} label="Дата съёмки" />
        Проверено до даты экспорта: <BasicDateRangePicker data={dates} field="exportDate" onSelect={() => {console.log(dates);}} label="Дата экспорта" />   
        <Button  sx={{mt: '200px'}}variant="contained" color="error"
        onClick = {
            () => {
                //console.log('task ok' + inputText);
                const url = ApiServer.api + "/api/api_videofile.php?act=set_accepted_by_date&exported_date=" + dates.exportDate + "&create_date=" + dates.createDate + "&city=" + Context.city;
                getDataJson(url, setResponse);  
            }
        }
        >ПРИНЯТЬ ВСЕ НЕПРОВЕРЕННЫЕ</Button>   

        
        </Card>

        <Card sx={{p: '10px', mt: '20px'}}>
        <Typography variant="h5" gutterBottom>
            Отметить файл
        </Typography>

          <Stack direction="row" alignItems="left" justifyContent="space-between" mb={3}>
               
         
                <InputWithButton inputFunction = {
                    (e) => {console.log(e);
                    e = encodeURI(e);
                    console.log(e);
            
                    const url = searchVideofile(e);
            
                    console.log("search url");
                    console.log(url);
                    getDataJson(url, setFilesInfo);          
                     } } 
                    inputString = "ввести имя файла" 
                    buttonString = "Загрузить"
            />
            </Stack>
                     {
                        filesInfo.map((fileInfo) => <FileAcceptComponent key={fileInfo.videofiles_id} fileInfo={fileInfo} setResponse={setResponse} />
                        )}
        </Card>
      </div>

    </Page>
  );
}