import React, { createContext, useState, useContext } from "react";

export const Context = createContext();

export function Provider({ children }) {
    const [task, setTask] = useState([]);

    const changeTask = (newTask) => setTask(newTask);

    const [cityPointsList, setCityPointsList] = useState([]);
    const changeCityPointsList = (newList) => setCityPointsList(newList);

    return (
        <Context.Provider value={{ task, changeTask, cityPointsList, changeCityPointsList }}>
            {children}
        </Context.Provider>
    );
};

export const useProvider = () => useContext(Context);