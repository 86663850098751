import { useProvider } from '../common_components/Provider';
import Settings from "../../settings";
import InputWithButton from '../common_components/inputWithButton';
import TaskView from './taskView';
import {ApiServer} from "../.././settings";
import { Context, FormatDate } from "src/Context";

async function getData(url, callback) {
  const response = await fetch(url);
      console.log(response);
      if (response.ok) {  //   если HTTP-статус в диапазоне 200-299
        //  получаем тело ответа (см. про этот метод ниже)
        const json = await response.json();
        //  console.log("json");
        //  console.log(json);
        callback(json);
      } else {
        alert(`Ошибка HTTP: ${response.status}`);
      }
}

function SearcherFragment() {
  const { task } = useProvider();  
  const { changeTask } = useProvider(); 

  

  return (
    <>
    <div style={{margin: '20px'}}>
    <InputWithButton inputFunction = {
      (e) => {console.log(e);
      e = encodeURI(e);
      console.log(e);
      
      const url = ApiServer.api + "/api/api_task.php"+`?taskTitle=${e}`;
      
      console.log("search url");
      console.log(url);
      getData(url, changeTask);
      
    } } 
    inputString = "ввести task_title ..." 
    buttonString = "Загрузить"
    />
    </div>
    <TaskView task= {task} />
    </>
  );
}

export default SearcherFragment;