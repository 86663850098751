import React, { useState } from "react";
import { useProvider } from '../common_components/Provider';

import {
    Container,
    Typography,
    Link
} from '@mui/material';

function TaskView( {task} ) {
    //  const { task } = useProvider();

    console.log("in task view");
    
    if (!task.length) return (
        <Container>
            <Typography variant="h4" color="error" gutterBottom>
                Задание не найдено
            </Typography>
        </Container>
    );

    console.log(task);
    const t = task[0];

    return (
        <Container>
            <Typography variant="h5" gutterBottom>
                Задание {t.task_title}
            </Typography>

            <Typography variant="body1" gutterBottom>
                Исполнитель <br />
                ФИО: {t.fio} <br />
                Номер телефона: {t.tel} <br />
                email: {t.email} <br />
            </Typography>

            <Typography variant="h6" gutterBottom>
                Отрезок для измерения "{t.street}" № {t.line_id}
            </Typography>

            <Typography variant="body1" gutterBottom>
                Дата измерения: {t.task_date} <br />
                Измерение проводится: с {t.time_mon_start} до {t.time_mon_stop} утром <br />
                и с {t.time_evn_start} до {t.time_evn_stop} вечером <br />

                <Link href={t.url}>
                Ссылка на координаты отрезка на Google картах
                </Link>
                <img src={t.img} />
            </Typography>

        </Container>
    );
}

export default TaskView;