import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import getDataJson from '../common_components/getDataJson';
import {ApiServer} from "../../settings";
import {useState} from "react";
import {SupportStyle} from "../common_components/searchStyle";
import InputWithButton from "../common_components/inputWithButton";

export default function VerifyDialogue(props) {
    const {onClose, open, videofiles_id, setVideoLine} = props;
    const [isShown, setIsShown] = useState(false)
    const [isFixed, setIsFixed] = useState(false)

    const handleClose = () => {
        onClose();
    };

    const setVideoVerification = (status, comment = '') => {
        const url = ApiServer.api + '/api/api_videofile.php?act=set_verify&videofiles_id=' + videofiles_id + '&verification_status=' + status + '&verification_comment=' + comment;
        getDataJson(url, setVideoLine);
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={false}>
            <DialogTitle>Видеофайл №{videofiles_id}</DialogTitle>
            <Container sx={{mb: 5}}>
                <div>
                    <Button sx={{width: 200, padding: 1, margin: 2}} variant="contained" onClick={() => {
                        setIsShown(false)
                        setIsFixed(false)
                        setVideoVerification(0)
                    }}>
                        Новый
                    </Button>
                    <Button sx={{width: 200, padding: 1, margin: 2}} variant="contained" onClick={() => {
                        setIsShown(false)
                        setIsFixed(false)
                        setVideoVerification(1)
                    }}>
                        В работе
                    </Button>
                    <Button sx={{width: 200, padding: 1, margin: 2}} variant="contained" onClick={() => {
                        setIsShown(false)
                        setIsFixed(true)
                        setVideoVerification(2)
                    }}>
                        Исправлено
                    </Button>
                    <Button sx={{width: 200, padding: 1, margin: 2}} variant="contained" onClick={() => {
                        setIsShown(true)
                        setIsFixed(false)
                        setVideoVerification(3)
                    }}>
                        Запросить допуск
                    </Button>
                    {isShown && (<div>
                        <InputWithButton inputFunction={
                            (e) => {
                                setVideoVerification(3, encodeURIComponent(e))
                                setIsShown(false)
                            }
                        }
                                         inputString="Введите комментарий для службы поддержки"
                                         buttonString="Отправить"
                                         InputStyle={SupportStyle}
                        />
                    </div>)}
                    {isFixed && (<div>
                        <InputWithButton inputFunction={
                            (e) => {
                                setVideoVerification(2, encodeURIComponent(e))
                                setIsFixed(false)
                            }
                        }
                                         inputString="Если вы переснимали это видео, укажите, пожалуйста, номер нового видео"
                                         buttonString="Отправить"
                                         InputStyle={SupportStyle}
                        />
                    </div>)}
                </div>
            </Container>
        </Dialog>
    );
}
