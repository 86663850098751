import React, { useState, useEffect, createContext, useContext } from 'react';
import {
  Card, Stack, Box, Container,
  Typography, Button, Grid, Paper, listClasses, ListItem, List
} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { ApiServer } from ".././settings"
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../components/Iconify';
import SearcherFragment from '../components/searcher_components/SearcherFragment';
// components
import { YMaps, Map, GeoObject, Placemark, ZoomControl, RulerControl } from "react-yandex-maps";
import Page from '../components/Page';
import { TableUsers } from 'src/components/table_users';
import TablePointTask from 'src/components/table_users/TablePointTask';
import TableUsersLineTaskSum from 'src/components/table_users/TableUsersLineTaskSum';

import getDataJson from 'src/components/common_components/getDataJson';
import BasicDateRangePicker from 'src/components/table_users/BasicDateRangePicker';
import { Context, FormatDate } from "src/Context";
import { set } from 'lodash';
import { faker } from '@faker-js/faker';
import InputWithButton from 'src/components/common_components/inputWithButton';
import LineInfoTableComponent from 'src/components/control/LineInfo';
// const userContext = createContext();
//  let currentCity = "";

// добавить поиск по адресу (чтобы ориентироваться по адресу человека для выдачи точек)
// и поиск по номеру точки

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CityButton = (props) => {
  // let city = 
  return <Button variant="contained" startIcon={<Iconify icon="ci:map" />}
    onClick={() => {
      Context.counter = Context.counter + 1;
      Context.city = props.city;
      //  currentCity = props.city;
      getDataJson(getUrlCity(props.city), props.callback);
      console.log("set users city=", props.city);
      getDataJson(getUrlUsers(props.city), props.callback2);
      props.clean();

    }}>
    {props.city}
  </Button>
};

function UserPointsModeButton(props) {
  const state = props.state;
  let buttonText = "показать все точки города";
  let captionText = "точки выбранного исполнителя";
  if (!state) { buttonText = "показать только точки исполнителя"; captionText = "все точки города"; };

  return <div style={{ marginTop: '10px' }}>
    Сейчас на карте: {captionText}
    <Button
      variant="contained"
      onClick={() => {
        console.log("userMode=", !state)
        props.func(!state);
        props.refreshMap(!state);
      }}

    >
      {buttonText}
    </Button>
  </div>
}

function SelectPointsButton(props) {
  const state = props.state;
  let buttonText = "Включить режим выбора нескольких точек";
  let captionText = "выбор одной точки";
  if (state) { buttonText = "Включить режим выбора одной точки"; captionText = "выбор нескольких точек" };

  return <div style={{ marginTop: '10px' }}>
    Режим: {captionText}
    <Button
      variant="contained"
      onClick={() => {
        console.log("userMode=", !state)
        props.func(!state);
      }}

    >
      {buttonText}
    </Button>
  </div>
}


export function getUrlCity(city) {
  return ApiServer.api + '/api/api_city.php?city=' + city + '&yamap=1';
}

function getUrlUsers(city) {
  return ApiServer.api + '/api/users.php?city=' + city;
}

function getUrlSetTask(date, line_id, users_id) {
  return ApiServer.api + '/api/setTask.php?date=' + date + '&line_id=' + line_id + '&users_id=' + users_id;
}


export function getColorCategory(category) {
  let res = 'islands#redCircleDotIcon'
  switch (category) {
    case "1а":
      res = 'islands#blueCircleDotIcon';
      break;
    case "2а":
      res = 'islands#greenCircleDotIcon';
      break;
    case "3т":
      // res =  'islands#greenSportIcon';
      res = 'islands#orangeCircleDotIcon';
      break;
    default:
      res = 'islands#redCircleDotIcon';
      break;
  }
  return res;
}

export function getColorTask(count_task, color, line_id = null, selectedPoints = [], findedPoint = "") {
  let res = color;
  console.log("findedPoint=", findedPoint);

  if (count_task > 3) {
    res = 'islands#redCircleDotIcon';
    res = 'islands#redCircleIcon';
    res = 'islands#redStretchyIcon';
  }

  if ((count_task < 3) && (count_task > 0)) {
    res = 'islands#redCircleDotIcon';
    res = 'islands#redCircleIcon';
    res = 'islands#redStretchyIcon';
  }

  if (count_task == 3) {
    res = 'islands#governmentCircleIcon';
    //  res = 'default#image';
  }

  if (line_id == findedPoint) {
    res = 'islands#violetStretchyIcon';
  }

  if (line_id) {
    const isLine = selectedPoints.some(item => item === line_id)
    if (isLine) {
      res = 'islands#blackStretchyIcon';
    }

  }



  return res;
}

export function getColorTaskSuccess(count_task, correct_count, color, line_id = null, selectedPoints = [], findedPoint = "", count_video, count_morning, count_evening) {
  let res = color;
  console.log("findedPoint=", findedPoint);

  // if (count_task > 3) {
  //   res = 'islands#redCircleDotIcon';
  //   res = 'islands#redCircleIcon';
  //   res = 'islands#redStretchyIcon';
  // }

  // if ((count_task < 3) && (count_task > 0)) {
  //   res = 'islands#redCircleDotIcon';
  //   res = 'islands#redCircleIcon';
  //   res = 'islands#redStretchyIcon';
  // }

  // if (count_task == 3) {
  //   res = 'islands#blueRepairShopCircleIcon';
  //   //  res = 'default#image';
  // }

  // if (line_id == findedPoint) {
  //   res = 'islands#brownCircleIcon';
  // }

  // if (line_id) {
  //   const isLine = selectedPoints.some(item => item === line_id)
  //   if (isLine) {
  //     res = 'islands#blackStretchyIcon';
  //   }

  // }

  // if (correct_count == 3) {
  //   res = 'islands#violetGovernmentCircleIcon';
  //   //  res = 'default#image';
  // }

  if (count_video > 0) {
    res = 'islands#redCircleDotIcon';
    res = 'islands#redCircleIcon';
    res = 'islands#redStretchyIcon';
  }

  if (count_task > 0) {
    res = 'islands#blueRepairShopCircleIcon';
    //  res = 'default#image';
  }

  if ((count_morning >= 3) && (count_evening >= 3)) {
    res = 'islands#violetGovernmentCircleIcon';
    //  res = 'default#image';
  }

  if (line_id == findedPoint) {
    res = 'islands#brownCircleIcon';
  }

  if (line_id) {
    const isLine = selectedPoints.some(item => item === line_id)
    if (isLine) {
      res = 'islands#blackStretchyIcon';
    }

  }


  return res;
}


const cityCoords = [
  [54.450174, 60.613012],
  [54.450174, 60.613012],
  [54.450174, 60.613012],
  [54.450174, 60.613012],
  [54.450174, 60.613012],
  [54.450174, 60.613012],
  [54.450174, 60.613012],
  [54.450174, 60.613012],
];



//  ffmpeg -i 00000_00000020220905153318_0001A.MP4 -vcodec libx265 -crf 28 fps-fps=30 00000_00000020220905153318_0001B.MP4
//  ffmpeg -i 00000_00000020220905153318_0001A.MP4 -vcodec libx265 -crf 28 00000_00000020220905153318_0001B.MP4


export default function MapYandex() {
  const [points, setPoints] = useState([]);
  const [caption, setCaption] = useState('');
  const [caption2, setCaption2] = useState('');
  const [taskErrors, setTaskErrors] = useState([]);
  const [users, setUsers] = useState([]);

  const [usersPointsMode, setUsersPointsMode] = useState(false); // false - показывает всеточки города, иначе - выбранного userа на указанном промежутке
  const [selectPointsMode, setSelectPointsMode] = useState(true);
  const [selectedPoints, setSelectedPoints] = useState([]);

  const [findedPoint, setFindedPoint] = useState(0);

  //  const [currentCity, setCurrentCity] = useState("");

  //  const [usersTask, setUsersTask] = useState([]);
  //  const [usersLineTaskSum, setUsersLineTaskSum] = useState([]);

  function refreshMap(mode = false) {
    let url = getUrlCity(Context.city);
    let date_start = getDateString(Context.pointData.dateStart);
    let date_end = getDateString(Context.pointData.dateEnd);
    if (mode) url = url + "&users_id=" + Context.pointData.users_id;
    //  if (mode) url = url + "&users_id=" + Context.pointData.users_id + "&date_start=" + date_start + "&date_end=" + date_end;
    getDataJson(url, setPoints);
  }

  const select = (state) => {
    setSelectedPoints([]);
    setSelectPointsMode(state);
    Context.linesList = "";
    setCaptionFromContext();
    refreshMap(usersPointsMode);

  }

  const setCaptionFromContext = () => {
    setCaption("№ " + Context.linesList + " " +
      Context.pointData.users_title +
      " c " + Context.pointData.dateStart + " по " + Context.pointData.dateEnd);
  }

  const onPlacemarkClick = point => () => {
    console.log("point", point);
    Context.pointData.line_id = point.line_id;
    setCaptionFromContext();
    // setCaption("№ "+point.line_id+" Категория дороги: "+point.category );
    console.log("pointData", Context.pointData);
    //**getDataJson(getUrlTask(point.line_id),setUsersTask); 

  };


  const addSelectedPoint = line_id => () => {
    const resArr = (selectPointsMode) ? [...selectedPoints, line_id] : [line_id];
    setSelectedPoints(resArr);
    Context.pointData.line_id = line_id;
    Context.linesList = resArr.join(", ");
    setCaptionFromContext();
    console.log("selectedPoints=", selectedPoints);
  };


  //  const [selectedUser, setSelectedUser] = useState({'users_id': '', 'title': ''});

  function setSelectedUser(userData) {
    Context.pointData.users_id = userData.users_id;
    Context.pointData.users_title = userData.users_title;
    // setCaption2('Выбран сотрудник '+userData.users_title);
    setCaptionFromContext();
    // getDataJson(getUrlLineTaskSum(userData.users_id),setUsersLineTaskSum); 

  }
  /*
      function analyseSetTask(json) {
        const prevLog = log;
        console.log("prevlog", prevLog);
        setLog(prevLog + " next " + json.error);
        console.log("newLog", prevLog + " next " + json.error);
      }
  
      
  */
  function addError(error) {
    let newErrors = taskErrors;
    newErrors.push(error);
    setTaskErrors(newErrors)
  }

  function cleanErrors() {
    let newErrors = taskErrors;
    newErrors.splice(0);
    setTaskErrors(newErrors)
  }

  function setTask(date, line_id, users_id) {
    console.log("setTask ", date, line_id, users_id);
    getDataJson(getUrlSetTask(date, line_id, users_id), addError);
  }

  function getDateString(date) {
    let dateStr = "0000-00-00";
    if (date) {
      const systemDate = new Date(date);
      const Month = systemDate.getMonth() + 1;
      dateStr = systemDate.getFullYear() + "-" + Month + "-" + systemDate.getDate();
    };
    return dateStr;
  }


  function setTasks() {
    let setErrors = [];
    console.log("point=", Context.pointData);

    let dateStart = new Date(Context.pointData.dateStart);
    let dateEnd = new Date(Context.pointData.dateEnd);

    console.log("selectedPoints=", selectedPoints);

    selectedPoints.forEach((line_id) => {
      let currentDate = dateStart.getTime();
      while ((dateEnd.getTime() - currentDate) >= 0) {
        let setDate = new Date(currentDate);
        let Month = setDate.getMonth() + 1; // нумерация месяцев начинается с 0
        setTask(setDate.getFullYear() + "-" + Month + "-" + setDate.getDate(), line_id, Context.pointData.users_id);
        currentDate = currentDate + 24 * 3600 * 1000;
      };
    }
    );

    refreshMap(usersPointsMode);
    return setErrors;
    //getDataJson(getUrlTask(Context.pointData.line_id),setUsersTask); 
  };


  /*
  useEffect(() => {
     console.log("useEffect");
     getDataJson(getUrlUsers(),setUsers);
    //  setPoints([
    //   [55.684758, 37.738521],
    //   [56.684758, 38.738521],
    //   [57.684758, 39.738521]
    // ])
  },[]);
*/

  function clean() {
    setCaption("");
    setCaption2("");
    // Context.pointData.dateStart = FormatDate(new Date());
    // Context.pointData.dateEnd = FormatDate(new Date());
    Context.linesList = "";
    Context.pointData.line_id = 0;
    Context.pointData.users_id = 0;
    Context.pointData.users_title = "";
    setCaptionFromContext();
  }

  useEffect(() => {
    console.log("loadCityData");
    //Context.counter = Context.counter + 1;
    //Context.city = props.city;
    const city = Context.city;
    //  currentCity = props.city;
    getDataJson(getUrlCity(city), setPoints);
    //console.log("set users city=", props.city);
    getDataJson(getUrlUsers(city), setUsers);
    clean();


  }, []);



  console.log("cityid", Context.city_id);

  return (
    <Page title="Maps">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom>
            Формирование задания для города {Context.city}
          </Typography>
        </Stack>
      </Container>

      <Grid container spacing={2} height="100%">
        <Grid xs={4} >

          <div style={{ margin: '20px', backgroundColor: 'white' }}>
            <div style={{ margin: '20px' }}>
              <Typography variant="h4" gutterBottom>
                Выбор дат
              </Typography>
              Начало съемки: <BasicDateRangePicker data={Context.pointData} field="dateStart" onSelect={setCaptionFromContext} label="Начало" />
              Конец съемки: <BasicDateRangePicker data={Context.pointData} field="dateEnd" onSelect={setCaptionFromContext} label="Конец" />
            </div>
          </div>
        </Grid>

        <Grid xs={4} >

          <div style={{ margin: '20px', backgroundColor: 'white' }}>
            <div style={{ margin: '20px' }}>
              <Typography variant="h4" gutterBottom>
                Выбор точек
              </Typography>

              <UserPointsModeButton
                state={usersPointsMode}
                func={setUsersPointsMode}
                refreshMap={refreshMap}
              />
              <SelectPointsButton
                state={selectPointsMode}
                func={select}
              />
            </div>
          </div>
        </Grid>

        <Grid xs={4} >

          <div style={{ margin: '20px', backgroundColor: 'white' }}>
            <div style={{ margin: '20px' }}>
              <Typography variant="h4" gutterBottom>
                Поиск точки
              </Typography>
              <InputWithButton inputFunction={
                (e) => {
                  console.log(e);
                  e = e.trim();
                  //console.log(e);
                  setFindedPoint(e);
                  refreshMap(usersPointsMode);
                }}
                inputString="ввести номер точки ..."
                buttonString="Искать"
              />
            </div>
          </div>
        </Grid>




        <Grid xs={12}>
          <Grid container spacing={2} height="100%" ml={2}>
            <Grid item xs={12} >
              <Typography variant="h4" gutterBottom>
                Задание:
              </Typography>
              <Typography variant="h7">
                {caption}
              </Typography>
            </Grid>
            <Grid item xs={12} >
              {caption2}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} >
          <Button sx={{ ml: 2 }}
            variant="contained"
            color="error"
            size="large"
            onClick={() => {
              cleanErrors();
              //  console.log("ошибки очищены")
              setTasks();
            }}
          >
            ВЫДАТЬ ЗАДАНИЕ
          </Button>

          <Button sx={{ ml: 2 }}
            variant="contained"
            size="large"
            onClick={() => {
              setSelectedPoints([]);
              Context.linesList = "";
              setCaptionFromContext();
              refreshMap(usersPointsMode);
            }}
          >
            ОЧИСТИТЬ ТОЧКИ
          </Button>

          <Button sx={{ ml: 2 }}
            variant="contained"
            size="large"
            onClick={() => {
              refreshMap(usersPointsMode);
            }}
          >
            ПЕРЕЗАГРУЗИТЬ КАРТУ
          </Button>

        </Grid>
        {/* <Grid item xs={12} >
          <List>

            {
              taskErrors.map(item => <ListItem key={faker.datatype.uuid()}><Typography>{item.error}</Typography></ListItem>)
              //  taskErrors.map(item => {console.log(item)})
            }
            
          </List>
        </Grid> */}

        <Grid item xs={8} >
          <YMaps enterprise query={{ apikey: '6f09d219-95c2-4de5-af8a-b4f6356c216e' }}>
            {/* <Map width="100%" height="60vh" defaultState={{ center: cityCoords[Context.city_id], zoom: 6 }}> */}
            <Map width="100%" height="60vh" defaultState={{ center: [54.819694, 90.136340], zoom: 6 }}>
              {/* <Map width="100%" height="60vh" defaultState={{  center: [55.751574, 37.573856], zoom: 9}}> */}
              {points.map((coordinate, i) =>
                <Placemark key={i}
                  geometry={coordinate.pos}
                  onClick={
                    //  onPlacemarkClick(coordinate)
                    addSelectedPoint(coordinate.line_id)
                  }
                  options={{
                    // preset: getColorTaskOperator(coordinate.count_task, coordinate.correct_count, getColorCategory(coordinate.category), coordinate.line_id)
                    preset: getColorTaskSuccess(coordinate.count_task, coordinate.correct_count, getColorCategory(coordinate.category), coordinate.line_id, selectedPoints, findedPoint, coordinate.count_video, coordinate.count_morning, coordinate.count_evening)
                    // preset: getColorTask(coordinate.count_task, getColorCategory(coordinate.category), coordinate.line_id, selectedPoints, findedPoint)
                  }}
                  // preset = 'islands#blueIcon'
                  properties={{
                    iconContent: coordinate.count_video,
                    hintContent: coordinate.category,
                    balloonContent: '№ ' + coordinate.line_id,
                    preset: 'islands#redSportIcon',
                  }}
                  modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                />)}
              <RulerControl />


            </Map>


          </YMaps>
        </Grid>
        <Grid item xs={4} >
          <TableUsers
            data={users}
            select={setSelectedUser}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <Item><TablePointTask data={usersTask} /></Item> */}
          <Item><TablePointTask line_id={Context.pointData.line_id} refreshMap={refreshMap} usersPointsMode={usersPointsMode}/></Item>
          <LineInfoTableComponent line_id={Context.pointData.line_id} />
        </Grid>
        <Grid item xs={6}>
          {/* <Item><TableUsersLineTaskSum users_id={Context.pointData.users_id}  data={usersLineTaskSum} /></Item> */}
          <Item><TableUsersLineTaskSum users_id={Context.pointData.users_id} users_title={Context.pointData.users_title} refreshMap={refreshMap}  usersPointsMode={usersPointsMode}/></Item>
        </Grid>
      </Grid>


    </Page>
  );
}