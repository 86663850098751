import React, { useState, useEffect, useContext } from 'react';
import {
    Stack, Box, Link,
    Typography, Button, Grid, TextField
} from '@mui/material';

import { NaprContext } from 'src/servises/naprContext';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useNavigate } from 'react-router-dom';

import { ApiServer } from ".././settings";
// components
import Page from '../components/Page';

import getDataJson from 'src/components/common_components/getDataJson';

import { faker } from '@faker-js/faker';
import { getURLVarArr } from './User';
import { getDirSymb } from './Count';


function checkNapravlCount(filesInfo){
    const naprCount = filesInfo.length/2;
    const pryamoe = filesInfo.filter(item => item.naptavlenie == 1);
    const obratnoe = filesInfo.filter(item => item.naptavlenie == 2);
    return (naprCount == pryamoe) && (naprCount == obratnoe); 
}
/*
export function getUserTasksUrl(){
    return ApiServer.api + "/api/api_count.php?act=getUserTasks";
}

export function getRandomUrlTask() {
    return ApiServer.api + '/api/users_task.php?&yamap=1&random=random';
}
*/

export function SimpleTextInput({ inputValue = "", inputText = "", changeFunction = f => f }) {
    return (
        <TextField
            value={inputValue}
            placeholder={inputText}
            onChange={event => {
                let text = event.target.value;
                changeFunction(text)}}
            type="text"
            multiline
            rows={2}
            required
        />
    );
}


function getTableDataUrl(videofilesId){
    const url = ApiServer.api + "/api/api_count.php?act=getVideofilesResultValues&videofilesId=" + videofilesId;
    return url;
}

function getCountStatusesUrl(videofilesId){
    const url = ApiServer.api + "/api/api_count.php?act=getParamCountStatuses&videofilesId=" + videofilesId;
    return url;
}


const CountFile = (props) => {

    const [speed, setSpeed] = useState(1);
    const [tableData, setTableData] = useState([]);
    const { napr, setNapr} = useContext(NaprContext);
    const naprArr = ["НЕ ОПРЕДЕЛЕНО", "ПРЯМОЕ", "ОБРАТНОЕ"];

     useEffect(() => {
          getDataJson(getTableDataUrl(props.videofiles_id), setTableData);
     }, []);

    // const fileLink = 'http://79.120.39.75:82/' + props.line_id + '/' + props.videofiles_id + '.mp4';
    const fileLink = "/convertedVideos/" + props.line_id + '/' + props.videofiles_id + '.mp4';

    function getNewNaprArr(oldArr, videofilesId, newNapr) {
        console.log(oldArr);
        const newArr = oldArr.filter((item) => item.videofiles_id !== videofilesId);
        console.log(newArr);
        newArr.push({"videofiles_id": videofilesId, "napravlenie": newNapr});
        setNapr([ ...newArr]);
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Файл {props.videofiles_id}
                </Typography>

            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>


                        <Box sx={{
                            bgcolor: 'background.paper',
                            boxShadow: 1,
                            borderRadius: 2,
                            p: 2,
                            mb: 10
                        }}>
                           
                            <video width="100%" src={fileLink} id={props.videofiles_id} controls></video> 
                            <Stack direction="row" alignItems="center" spacing={2}>

                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={
                                        () => {
                                            const newSpeed = speed + 0.5;
                                            // console.log('speed up');
                                            document.getElementById(props.videofiles_id).playbackRate = newSpeed;
                                            setSpeed(newSpeed);

                                        }
                                    }
                                >
                                    + 0,5
                                </Button>

                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={
                                        () => {
                                            if (speed > 0) {
                                                const newSpeed = speed - 0.5;
                                                // console.log('speed down');
                                                document.getElementById(props.videofiles_id).playbackRate = newSpeed;
                                                setSpeed(newSpeed);
                                            };

                                        }
                                    }
                                >
                                    - 0,5
                                </Button>

                                <Typography variant="h5" gutterBottom>
                                    Скорость видео: {speed}
                                </Typography>

                            </Stack>

                            <p>Направление: {(napr[props.naprIndex] !== undefined) ? naprArr[napr[props.naprIndex].napravlenie] : "НЕТ СВЯЗИ С БАЗОЙ ДАННЫХ"}</p>
                            <Button onClick={() => {
                                // console.log(napr);
                                getNewNaprArr(napr, props.videofiles_id, 1);
                                }}
                                >установить прямое</Button>
                            <Button>установить обратное</Button>
                        </Box>
                    </Grid>

                    <Grid item xs={8}>

                        <Stack direction="column" spacing={5}>
                            <ResultTable tableData={tableData} videofiles_id={props.videofiles_id}/>

                        </Stack>

                    </Grid>
                </Grid>
            </Grid>
        </>

    );
}


function ResultLine(props) {
    return(
        <>
        <Grid xs={3}>
             {props.title}
            </Grid>
            <Grid xs={1}>
            <Typography justifyContent='center' textAlign='center'>{props.amounts[1]}
                </Typography>
            </Grid>
            <Grid xs={1}>
            <Typography justifyContent='center' textAlign='center'>
                {props.amounts[2]}
                </Typography>
            </Grid>
            <Grid xs={1}>
            <Typography justifyContent='center' textAlign='center'>
                {props.amounts[3]}
                </Typography>
            </Grid>
            <Grid xs={1}>
            <Typography justifyContent='center' textAlign='center'>
                {props.amounts[4]}
                </Typography>
            </Grid>
            <Grid xs={1}>
            <Typography justifyContent='center' textAlign='center'>
                {props.amounts[5]}
                </Typography>
            </Grid>
            <Grid xs={1}>
                {
                    (props.dir) ? getDirSymb(props.dir) : <></>
                }
            </Grid>
            <Grid xs={3}>
                {props.note}
            </Grid>
        </>
    );

}

const getStatus = (num) => {
    return (num === 1) ? "Принято" : "Отклонено"; 
};


function StatusButtonsComponent(props){
    return(

        <Grid xs={1}>
            
        <Stack direction="column" align="left">
            <Typography variant="h7">{getStatus(props.statuses[props.category])}</Typography>
            
            { (props.statuses[props.category] === 0 ) ?
            <Button sx={{mr: 1}} size="large" variant="contained" onClick={() => {props.setNewStatus(props.category, 1)}}>Принять</Button> :
            <Button sx={{mr: 1}} size="large" variant="contained" color="error" onClick={() => {props.setNewStatus(props.category, 0)}}>Отклонить</Button>
            }
        </Stack>
   
        </Grid>
    );   
}



function StatusesButtons(props){

    const [statuses, setStatuses] = useState([0,0,0,0,0,0]);
    const [errorText, setErrorText] = useState({error: ""});

    const sendMainData = () => {
        const statusesData = btoa(JSON.stringify(statuses)); 
        const middleData = btoa(JSON.stringify(props.tableData[props.tableData.length - 1].amounts)); // последний элемент tableData - средние
    
        const url = ApiServer.api + "/api/api_count.php?act=saveMainData&statusesData=" + statusesData + "&middleData=" + middleData + "&videofilesId=" + props.videofiles_id;
        getDataJson(url, setErrorText);
    }


    useEffect(() => {
        getDataJson(getCountStatusesUrl(props.videofiles_id), setStatuses);
   }, []);

    const setNewStatus = (category, newValue) => {
        console.log("newstatus", newValue);
        console.log("statuses", statuses);

        let newStatuses = {};
        Object.assign(newStatuses, statuses);
        newStatuses[category] = newValue;
        
        setStatuses(newStatuses);
        console.log("statuses new", newStatuses);
    }

    return (
        <>
        <Grid xs={3}>
                Статус
        </Grid>
            <StatusButtonsComponent category={1} statuses={statuses} setNewStatus={setNewStatus}/>
            <StatusButtonsComponent category={2} statuses={statuses} setNewStatus={setNewStatus}/>
            <StatusButtonsComponent category={3} statuses={statuses} setNewStatus={setNewStatus}/>
            <StatusButtonsComponent category={4} statuses={statuses} setNewStatus={setNewStatus}/>
            <StatusButtonsComponent category={5} statuses={statuses} setNewStatus={setNewStatus}/>

            <Grid xs={1}>
                
            </Grid>
            <Grid xs={3}>
                
            </Grid>
        

        
            <Button variant="contained" color="error" size="large" sx={{mt: 2}} style={{width: '100%'}} onClick={() => {sendMainData()}}>
                Отправить результаты проверки
            </Button>
            <Typography  style={{width: '100%'}} justifyContent='center' textAlign='center'>{errorText.error}</Typography>

            </>
       
        
    );
}


export function ResultTable(props) {
//     const [statuses, setStatuses] = useState([0,0,0,0,0,0]);

//     useEffect(() => {
//         getDataJson(getCountStatusesUrl(props.videofiles_id), setStatuses);
//    }, []);

    return(
        <>
        <Grid container>
            <Grid xs={3}>
            ФИО
            </Grid>
            <Grid xs={1}><Typography justifyContent='center' textAlign='center'>I</Typography>
            </Grid>
            <Grid xs={1}><Typography justifyContent='center' textAlign='center'>II</Typography>
            </Grid>
            <Grid xs={1}><Typography justifyContent='center' textAlign='center'>III</Typography>
            </Grid>
            <Grid xs={1}><Typography justifyContent='center' textAlign='center'>IV</Typography>
            </Grid>
            <Grid xs={1}><Typography justifyContent='center' textAlign='center'>V</Typography>
            </Grid>
            <Grid xs={1}>
            Напр.
            </Grid>
            <Grid xs={3}>
            Коммент
            </Grid>

            {
                        props.tableData.map(item =>
                            <ResultLine {...item}/>
                        )
            }
            <StatusesButtons videofiles_id={props.videofiles_id} tableData={props.tableData}/>

        </Grid>
        </>
        

    );
}



export default function CountAdminTask() {

    const [taskFilesInfo, setTaskFilesInfo] = useState({'task_info': [], 'files_info': []});
    const [countUsers, setCountUsers] = useState([]);
    const [napr, setNapr] = useState([]);

    const navigate = useNavigate();

    const showTaskInfo = (task_title) => {
        console.log("showTaskInfo", task_title);
        const url = ApiServer.api + "/api/api_count.php?act=getTask&taskTitle=" + task_title;
        getDataJson(url, setTaskFilesInfo);

        const urlUsers = ApiServer.api + "/api/api_count.php?act=getCountTaskUsers&taskTitle=" + task_title;
        getDataJson(urlUsers, setCountUsers);
    }

    useEffect(() => {
        const task_title = getURLVarArr().task_title;
        showTaskInfo(task_title);
    }, []); 

    useEffect (() => {
        setNapr(taskFilesInfo.files_info.map((item) => { return {"videofiles_id": item.videofiles_id, "napravlenie": item.napravlenie}}));
    }, [taskFilesInfo]);

    return (
        <Page title="Count">
            <NaprContext.Provider value={{"napr": napr, "setNapr": setNapr}}>
            <Grid container>
                <Grid xs={6}>
                    <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>

                        <Typography variant="h4" gutterBottom>
                            Контроль подсчета автомашин
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Задание {taskFilesInfo.task_info.task_title}
                        </Typography>
                        <Typography  variant="h8" >Выполняли подсчет:</Typography>
                        {
                            countUsers.map((item) => <Typography  variant="h8" >{item.title}</Typography>)
                        }

                    </Stack>
                    <img src={taskFilesInfo.task_info.img} />
                    <Link href={taskFilesInfo.task_info.url}>
                        Ссылка на координаты отрезка на Google картах
                    </Link>
                </Grid>

                <Grid xs={6}>
                    <Button variant="contained" size="large" color="error" sx={{mt: 3}}onClick= {() => {navigate('/count_admin_main', { replace: true });}} >ВЕРНУТЬСЯ К СПИСКУ ЗАДАНИЙ</Button>

                    <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>

                        <Typography variant="h5" sx={{mt:5}} gutterBottom>
                            Категории автомашин
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                        I - легковые автомобили
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                        II - автофургоны и микроавтобусы до 3,5 тонн
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                        III - грузовые автомобили от 3,5 до 12 тонн
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                        IV - грузовые автомобили свыше 12 тонн
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                        V - автобусы свыше 3,5 тонн
                        </Typography>

                    </Stack>
                </Grid>   

                
                <Grid xs={12}>
                    {
                        taskFilesInfo.files_info.map((item, index) =>

                            
                            <CountFile key={faker.datatype.uuid()} task_title= {taskFilesInfo.task_info.task_title} count_task_id={taskFilesInfo.task_info.users_task_id} naprIndex={index} { ...item}/>
                            
                            
                        )
                    }
                </Grid>

            </Grid>

            <Button disabled={!(checkNapravlCount(napr))}>СОХРАНИТЬ НАПРАВЛЕНИЯ</Button>
            </NaprContext.Provider>
        </Page>
    );
}