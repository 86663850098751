import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, TextField
  } from '@mui/material';
  
  import { ApiServer } from ".././settings"
  // components
  import MaterialReactTable from 'material-react-table';
  import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
  import { YMaps, Map, GeoObject, Placemark, RulerControl } from "react-yandex-maps";
  import Page from '../components/Page';
  
  import getDataJson from 'src/components/common_components/getDataJson';
  
  import { getUrlLineTaskSum } from 'src/components/table_users/TableUsersLineTaskSum';
  import { getUrlUserDateTasks } from 'src/components/table_users/TaskDialog';
  import { getColorCategory, getColorTask } from './MapYandex';
  import TaskView from 'src/components/searcher_components/taskView';
  import { getUrlCity } from './MapYandex';
  import { getURLVarArr } from './User';
  import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDateRangePickerDefaultizedProps } from '@mui/x-date-pickers-pro/DateRangePicker/shared';




//   const getUrlCountUsers = () => {
//     const url = ApiServer.api + "/api/api_count.php?act=getCountUsers";
//     return url;
//   }

//   const getUrlMainCount = () => {
//     const url = ApiServer.api + "/api/api_count.php?act=getMainCount";
//     return url;
//   }

const getUrlCountUsers = ApiServer.api + "/api/api_count.php?act=getCountUsers";
const getUrlCountDays = ApiServer.api + "/api/api_count.php?act=getCountDays";
const getUrlMainCount = ApiServer.api + "/api/api_count.php?act=getMainCount";

function getUrlUserMainInfo(users_id){
    const url = ApiServer.api + "/api/api_count.php?act=getUserMainInfo&usersId=" + users_id;
    return url;
}

const CountUsersTable = (props) => {

    const columns = useMemo(
      () => [
        {
           accessorKey: 'title', //access nested data with dot notation
           header: 'ФИО',
        //    size: 10,
        },
        {
          accessorKey: 'email',
          header: 'Логин',
        },
        {
          accessorKey: 'filescount',
          header: 'Посчитано',
        //   size: 10,
        },
      ],
      [],
    );
  
    return (
      <MaterialReactTable
        columns={columns}
        data={props.data}
        enableRowActions
        enablePinning={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
  
        enableColumnActions={false}
        enableColumnFilters
        enablePagination={false}
        enableSorting
  
        renderRowActions={({ row }) => (
          <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5rem' }}>
            <Button
              color="primary"
              onClick={() => {
                console.info('Info', row.original.title, row.original.users_id);
                getDataJson(getUrlUserMainInfo(row.original.users_id), props.setUserMainInfo); 
                //  props.setUserInfo(row.original.users_id);
              }}
            >
              <VisibilityIcon />
            </Button>
  
          </div>
        )}
      />
    );
  }

  const CountDaysTable = (props) => {

    const columns = useMemo(
      () => [
        {
           accessorKey: 'day', //access nested data with dot notation
           header: 'День',
        //    size: 10,
        },
        {
          accessorKey: 'filescount',
          header: 'Посчитано',
        //   size: 10,
        },
      ],
      [],
    );
  
    return (
      <MaterialReactTable
        columns={columns}
        data={props.data}
        enableRowActions={false}
        enablePinning={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
  
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting

      />
    );
  }

  
  export default function Statistics() {
    const [statUsers, setStatUsers] = useState([]);
    const [statDays, setStatDays] = useState([]);
    const [mainCount, setMainCount] = useState([]);
    const [userMainInfo, setUserMainInfo] = useState([]);
  
    useEffect(() => {
       getDataJson(getUrlCountUsers, setStatUsers); 
    }, []);

    useEffect(() => {
        getDataJson(getUrlCountDays, setStatDays); 
     }, []);

    useEffect(() => {
        getDataJson(getUrlMainCount, setMainCount); 
     }, []);

    return (
        <Page title="Statistics">
            
            <Container>
                <Stack direction="column" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom textAlign="center">
                    Статистика
                    </Typography>
                    <Typography variant="h5" gutterBottom textAlign="center">
                    Посчитано {(mainCount.length === 1) ? mainCount[0].filescount : ""} файлов <br/>
                    Участвуют {statUsers.length} человек <br/><br/>
                    </Typography>

                </Stack>

            </Container>


        <Grid container spacing={2}>
            <Grid xs={3} spacing={2}>
                    <Typography variant="h6" gutterBottom textAlign="center">
                        Статистика по дням
                    </Typography>
                <CountDaysTable data={statDays}/>
            </Grid>

            <Grid xs={6} spacing={2}>
                    <Typography variant="h6" gutterBottom textAlign="center">
                        Статистика по участникам
                    </Typography>
                <CountUsersTable data={statUsers} setUserMainInfo={setUserMainInfo}/>
            </Grid>

            <Grid xs={3} spacing={2}>
                    <Typography variant="h6" gutterBottom textAlign="center">
                        Статистика по дням для участника {(userMainInfo.length > 0) ? userMainInfo[0].title : ""}
                    </Typography>
                <CountDaysTable data={userMainInfo}/>
            </Grid>


        </Grid>


          {/* <TableReportLines data={data} />   */}
      </Page>
    );
  }