import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { Context, FormatDate } from "src/Context";
import { ApiServer } from 'src/settings';
//  import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import users from 'src/_mock/user';

// ----------------------------------------------------------------------
async function loginUser(credentials) {

  //  console.log('credentials=', credentials);
  console.log('postdata=', JSON.stringify(credentials));
  const url = ApiServer.api + '/api/login.php';
  console.log('url=', url);
  console.log("credentials",credentials);
  console.log('Context.token=', Context.token);
  return fetch(url, {
    method: 'POST',
    // credentials: "include",
    // headers: {
    //  'Content-Type': 'application/json'
    // },
    // headers: {
    //   Cookie: 'air_token1=123'+Context.token
    // },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function LoginForm({setUserStatus}) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  
  const handleSubmit = async e => {
    e.preventDefault();
    let token = Context.token
    const logindata = await loginUser({
      username,
      password,
      token
    });
    
    //*  console.log("token=", token);
    //*  setToken(token);
    // let nextPath = (logindata.air_status == 2) ?  '/dashboard/map' : '/user?users_id='+ logindata.users_id;

    //  let nextPath = (logindata.air_status == 2) ?  '/dashboard/map' : '/count_admin_main';

    //let nextPath = (logindata.air_status == 5) ?  '/count' : '/count';

    //let nextPath = (logindata.air_status == 5) ? '/count_admin_main' : '/count';
    //let nextPath = (logindata.air_status == 5) ? '/count_admin_main' : '/count';
    let nextPath = (logindata.air_status == 7) ? '/accept' : '/count';

    //  navigate('/user?users_id='+ logindata.users_id, { replace: true });
    navigate(nextPath, { replace: true });
    console.log("Я получил статус", logindata.air_status);
    console.log(logindata);
    Context.token = logindata.token;
    Context.city = logindata.city;
    Context.city_id = logindata.city_id;
    // setCookie('name_air', logindata.token, { path: '/' });
    setUserStatus(logindata.air_status);
  }

  return (
    <div className="login-wrapper">
      <form onSubmit={handleSubmit}>
        
          <TextField fullWidth name="Email address" label="Email address" onChange={e => setUserName(e.target.value)} />
        
        
          <TextField  sx={{ mt: 2 }} fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        
        

          <Button sx={{ mt: 2 }} fullWidth size="large" type="submit" variant="contained" >
            Login
          </Button>
       
      </form>
    </div>
  )
}

LoginForm.propTypes = {
  setUserStatus: PropTypes.func.isRequired
};
