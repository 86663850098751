import React, { useState, useEffect,createContext, useContext } from 'react';
import {
    Card, Stack, Box, Container,
    Typography, Button, Grid,Paper, listClasses, ListItem, List, Link
  } from '@mui/material';
import Settings from 'src/settingsServer';
import Page from 'src/components/Page';
import InputWithButton from 'src/components/common_components/inputWithButton';
import LineInfoTableComponent from 'src/components/control/LineInfo';

export const show = (str) => {console.log(str)};
  
  export default function LineControl() {
    const [lineId, setLineId] = useState("");
    
    return (
    //   <Page title="Searcher">
        // <Container>
        <>
          <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                Посмотреть файлы точки {lineId}
                </Typography>

          </Stack>
                <InputWithButton inputFunction = {
                    (e) => {console.log(e);
                    e = encodeURI(e);
                    console.log(e);
                    setLineId(e);
                    // const url = getVideofileById(e);
            
                    // console.log("search url");
                    // console.log(url);
                    // getDataJson(url, setFileInfo);          
                     } } 
                    inputString = "ввести номер точки" 
                    buttonString = "Загрузить"
            />
            <LineInfoTableComponent line_id={lineId} />
            </>
            
    );
  }