

import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { ApiServer } from 'src/settings';

import PropTypes from 'prop-types';

// ----------------------------------------------------------------------
async function registerUser(credentials) {

  //  console.log('credentials=', credentials);
  console.log('postdata=', JSON.stringify(credentials));
  let url = ApiServer.api + '/api/register.php';
  console.log('registerUrl=', url);

  return fetch(url, {
    method: 'POST',
    //headers: {
    //  'Content-Type': 'application/json'
    //},
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function RegisterForm() {
  const [firstname, setFirstName] = useState();
  const [secondname, setSecondName] = useState();
  const [email, setEmail] = useState();
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const [city, setCity] = useState();
  const [tel, setTel] = useState();

  const [errorText, setErrorText] = useState();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const register = await registerUser({
      firstname,
      secondname,
      email,
      password1,
      password2,
      city,
      tel
    });
    console.log("register=", register);
    setErrorText(register.error);
    // setToken(token);
    //  navigate('/dashboard/task', { replace: true });
  }

  return (
    <div className="login-wrapper">
      <form onSubmit={handleSubmit}>
        <div >
          <TextField fullWidth name="First Name" label="Фамилия" onChange={e => setFirstName(e.target.value)} />
        </div>
        <div >
          <TextField fullWidth name="Second Name" label="Имя" onChange={e => setSecondName(e.target.value)} />
        </div>
        <div >
          <TextField fullWidth name="Tel" label="Номер телефона" onChange={e => setTel(e.target.value)} />
        </div>
        <div >
          <TextField fullWidth name="City" label="Город" onChange={e => setCity(e.target.value)} />
        </div>
        <div >
          <TextField fullWidth name="email" label="Логин" onChange={e => setEmail(e.target.value)} />
        </div>
        <div>
          <TextField fullWidth
            name="password1"
            label="Пароль"
            type={showPassword ? 'text' : 'password'}
            onChange={e => setPassword1(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <TextField fullWidth
            name="password2"
            label="Повторите пароль"
            type={showPassword ? 'text' : 'password'}
            onChange={e => setPassword2(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>

          <Button fullWidth size="large" type="submit" variant="contained" >
            Register
          </Button>
        </div>
      </form>
      <Typography>{errorText}</Typography>
    </div>
  )
}
