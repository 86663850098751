// material
import { alpha, useTheme } from '@mui/material/styles';
import { GlobalStyles,Button, Typography } from '@mui/material';
import React, { useState, useEffect,useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { PropaneSharp } from '@mui/icons-material';


const Example = (props) => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'title', //access nested data with dot notation
        header: 'Имя',
      },
      {
        accessorKey: 'url',
        header: 'url',
      },
      {
        accessorKey: 'path_dir', //normal accessorKey
        header: 'Папка',
      },
      {
        accessorKey: 'city_title', //normal accessorKey
        header: 'Город',
      }
    ],
    [],
  );

  return <MaterialReactTable 
          columns={columns} 
          data={props.data} 
          enableRowActions
          
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enablePagination={false}
          muiTableContainerProps={{
            // ref: tableContainerRef, //get access to the table container element
            sx: { maxHeight: '60vh' }, //give the table a max height
            // onScroll: (
            //   event: UIEvent<HTMLDivElement>, //add an event listener to the table container element
            // ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
          }}
          renderRowActions={({ row }) => (
            <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5rem' }}>       
            {/* <Button          variant="contained"
                 color="primary"
                 onClick={() => {
                   console.info('View Profile', row.original.title);
                   props.view(row.original.users_id);
                 }}
               >
                View
               </Button> */}
               <Button
                 variant="contained"
                 color="error"
                 onClick={() => {
                   console.info('Select', row.original.title);
                   props.select({'users_id': row.original.users_id, 'users_title': row.original.title});
                 }}
               >
                 Выбрать
               </Button>
             </div>
           )}
    />;
};


export default function TableUsers(props) {
  //  const theme = useTheme();

  // const background = {
  //   backdropFilter: 'blur(6px)',
  //   WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  //   backgroundColor: alpha(theme.palette.background.default, 0.72)
  // };

  useEffect(() => {
    console.log("TableUsers", props);
   
 },[]);

 const modData = props.data.map((item) => { return { ...item, url: 'https://air.av-hd.ru/user?users_id=' + item.users_id}})

  return <div>
    <Typography variant="h6">
    Список исполнителей:
    </Typography>
    {/* <Example data={props.data} select={props.select} view={props.view}/> */}
    <Example data={modData} select={props.select} view={props.view}/>
    </div>
  // return <div>TableUsers <BasicDateRangePicker/></div>
}
