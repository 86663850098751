import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, TextField
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { ApiServer } from "../.././settings"
// components
import MaterialReactTable from 'material-react-table';
// import { MRT_Localization_RU } from 'material-react-table/locales';
import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';

import getDataJson from 'src/components/common_components/getDataJson';


const TableCityProgress = (props) => {

    const columns = useMemo(
        () => [
            {
                accessorKey: 'title', //access nested data with dot notation
                header: 'Параметр',
                //  size: auto,
            },
            {
                accessorKey: 'summa',
                header: 'Значение',
                align: "right",
                size: 10,
            },
            // {
            //     accessorKey: 'procent',
            //     header: 'Процент',
            //   //   size: 10,
            //   }
        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={props.data}
            // enableRowActions={true}
            enablePinning={false}
            enableTableHead={false}
            enableTopToolbar={false}
            // enableBottomToolbar={false}

            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            // localization={MRT_Localization_RU}
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-child(2n+1)': {
                        backgroundColor: 'silver'
                    }
                })
            }}

            muiTableBodyRowProps={{
                sx: {
                    height: '10px',

                }
            }} muiTableBodyCellProps={{
            sx: {
                p: '2px 16px'
            }
        }}

            // renderRowActions={({ row }) => (
            //     <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            //       <EditIcon
            //       onClick={() => {
            //         //console.log("renderRowActions",props.data.splice(row.index, 1));
            //         console.log("renderRowActions", row.original.line_id);
            //         props.setCurrentLine(row.original.line_id);
            //         props.setOpen(true);

            //         // props.data.splice(row.index, 1); //assuming simple data table
            //         // setData([...data]);
            //       }}
            //       />

            //     </Box>
            //   )}

        />
    );
}


export default function ReportCityTable(props) {
    const [statLine, setStatLine] = useState([]);

    useEffect(() => {
        const url = ApiServer.api + '/api/report_city.php?&city=' + props.city;
        getDataJson(url, setStatLine);
    }, []);

    return (
        // <div style={{paddingBottom: "50px",width: "30vw"}}>
        <Grid item  xs={4}  >
            <Typography variant="h6">{props.city}</Typography>
            <TableCityProgress data={statLine}/>
        </Grid>
        // </div>
    );
}
