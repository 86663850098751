import React, {useState, useEffect} from "react";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
//  import GridItem from '@mui/material/GridItem';
import {faker} from '@faker-js/faker';
import {ImgDir} from 'src/settings';
import {ApiServer} from 'src/settings';
import getDataJson from "../common_components/getDataJson";
import InputWithButton from "../common_components/inputWithButton";
import {show} from "../../pages/FileControl";
import {SimpleTextInput} from "../../pages/Count";
import {CommentStyle} from "../common_components/searchStyle";

export const verifyArr = ["Файл не проверен программой", "Файл корректный", "Файл некорректный"];

export default function FileInfo(props) {


    //  console.log(verifyArr);

    console.log("fileprops=", {props});

    const [fileNames, setFileNames] = useState([]);
    const [element, setElement] = useState('<div>Загрузка</div>');
    const folder = props.videofiles_id;
    const verify = props.verify;
    console.log("verify=", verify);

    useEffect(() => {
        console.log("FileInfo", props);
        const url = ApiServer.api + '/api/getFilesList.php?folder=' + folder;
        getDataJson(url, setFileNames);
        // props.users_id
    }, [props.videofiles_id]);

    console.log("filenames=", fileNames);

    useEffect(() => {
        const elementArr = fileNames.map(item => <Grid xs={3} key={faker.datatype.uuid()} {...item}>
            {item}
            {/* <img src={"/imagesByVideos/" + folder + "/"+item}/> */}
            <img src={"/imgForConverted/" + folder + "/" + item}/>
        </Grid>);
        setElement(elementArr);
    }, [fileNames]);

    return (
        <>
            <Card>
                <Container sx={{mb: 10}}>
                    <Typography variant="h6">Bидеофайл №{props.videofiles_id} точки {props.line_id} </Typography>
                    <Typography variant="h6">Информация о съемке: {props.info} </Typography>
                    <Typography>Старт оригинальной съемки: {props.create_date} </Typography>
                    <Typography>Длина оригинальной съемки: {props.time_length} с</Typography>
                    <Typography>Старт обрезанного файла: {props.start_converted_time}</Typography>
                    <Typography>Имя экспортированного файла: {props.exported_filepath}</Typography>
                    <Typography>Категория точки: {props.line_category}</Typography>
                    <Typography>Время съемки: с {props.time_mon_start} до {props.time_mon_stop} утром
                        и с {props.time_evn_start} до {props.time_evn_stop} вечером </Typography>
                    <Typography>Ошибки: {props.files_status}</Typography>
                    <Typography>{verifyArr[verify]}</Typography>
                    <InputWithButton inputFunction={
                        (e) => {
                            console.log(e);
                            e = encodeURIComponent(e)
                            console.log(e);
                            const url = ApiServer.api + "/api/api_videofile.php?act=set_comment&comment="
                                + e + "&videofiles_id=" + props.videofiles_id;
                            getDataJson(url, show);
                        }
                    }
                                     inputString="Введите комментарий"
                                     buttonString="Отправить"
                                     InputStyle={CommentStyle}
                    />
                    <div></div>
                    <Grid container>
                        {/* {
            fileNames.map(item => <Grid xs={3} key={faker.datatype.uuid()} {... item}>
                                    {item}
                                    <img src={folder+"/"+item} />
                                  </Grid>)
          } */}
                        {element}
                    </Grid>
                </Container>
            </Card>
        </>
    );
}

// Sample <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

