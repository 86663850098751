import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';
import {
  Card, Stack, Box, Container, Link,
  Typography, Button, Grid, Paper, TextField
} from '@mui/material';

import { ApiServer } from ".././settings"
// components
import MaterialReactTable from 'material-react-table';
import { YMaps, Map, GeoObject, Placemark, RulerControl } from "react-yandex-maps";
import Page from '../components/Page';

import getDataJson from 'src/components/common_components/getDataJson';

import { getUrlLineTaskSum } from 'src/components/table_users/TableUsersLineTaskSum';
import { getUrlUserDateTasks } from 'src/components/table_users/TaskDialog';
import { getColorCategory, getColorTask } from './MapYandex';
import { getURLVarArr } from './User';
import { getUrlTask } from 'src/components/table_users/TablePointTask';
import { getVideofilesByTaskUrl } from './Control';
import TaskView from 'src/components/searcher_components/taskView';
import { verifyArr } from 'src/components/control/FileInfo';
import InputWithButton from 'src/components/common_components/inputWithButton'; 
import { show } from './Control';
import { countBy } from 'lodash';

export function getRandomUrlTask() {
  return ApiServer.api + '/api/users_task.php?&yamap=1&random=random';
}

function checkConvertStatus(taskList) { // менять статус задания на выполнено/не выполнено можно только если есть 4 хороших файла (verify 1)
  const succeessTasks = taskList.filter(task => task.task_status_id == 4);
  console.log("succeessTasks=", succeessTasks);
  return ( succeessTasks.length != 5 );
}

const ConvertVideofile = (props) => {
    const videofiles_id = props.videofile.videofiles_id;
    const status = verifyArr[props.videofile.verify];

    return (
        <Grid container>
            <Grid item xs={4}>
                <Typography>
                    {videofiles_id} <br/>
                    {status} <br/>
                    {props.videofile.filename} 
                </Typography>
            </Grid>
            <Grid item xs={4}>
                {/* файл1 */}
            <img src={"/imagesByConverted/" + videofiles_id + "/start.jpg"}/>
            </Grid>
            <Grid item xs={4}>
                {/* файл2 */}
            <img src={"/imagesByConverted/" + videofiles_id + "/stop.jpg"}/>
            </Grid> 
        </Grid>
    );
}




const ConvertTask = (props) => {
    const task_title = props.task.task_title;
    const task_date = props.task.task_date;
    const fio = props.task.fio;
    const task_status = props.task.task_status;

    const [videofiles, setVideofiles] = useState({files_info: []});

    useEffect(() => {
        console.log("Videofiles for task");
        getDataJson(getVideofilesByTaskUrl(task_title), setVideofiles);
      }, [props.tasks]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5">
                    {fio+" "}
                    задание {task_title}
                    {" "+task_status+" "}
                    {task_date+" c "+props.task.time_mon_start+" до "+props.task.time_mon_stop+" и c "+props.task.time_evn_start+" до "+props.task.time_evn_stop} <br/>
                </Typography>
                <Link href={props.task.url}>
                Ссылка на координаты отрезка на Google картах
                </Link>
            </Grid>
            <Grid item xs={12}>
            {
            videofiles.files_info.map(
                videofile => 
                    <ConvertVideofile videofile={videofile}/>
            )
            }
            </Grid>
        </Grid>
    );
}


export default function Convert() {
  //  const getLineId = getURLVarArr().line_id;
  const [ lineId, setLineId ] = useState( 0 );
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (tasks.length > 0) setLineId(tasks[0].line_id);
 },[tasks]);



  return (
    <Page title="Convert">
      <Container>
          <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                Проверить точку
                </Typography>

          </Stack>
                <InputWithButton inputFunction = {
                    (e) => {console.log(e);
                    e = encodeURI(e);
                    console.log(e);
                    setLineId(e);
            
                    //  const url = getVideofilesByTaskUrl(e);
            
                    //  console.log("search url");
                    //  console.log(url);
                    //  getDataJson(url, setTaskFilesInfo);  
                    
                    getDataJson(getUrlTask(e), setTasks);
                     } } 
                    inputString = "ввести line_id ..." 
                    buttonString = "Загрузить"
          />
      </Container>
      <Container>
            <Button 
            variant="contained"
            color="error"
            onClick = {
                () => {
                    console.log('click random');
                    //  const url = ApiServer.api + "/api/api_task.php?act=control&random=random";
                    //getDataJson(url, setTaskFilesInfo);
                    getDataJson(getRandomUrlTask(), setTasks);  
                }
            }>
                Проверить случайную
            </Button>
      </Container>


      <Container>
        <Stack direction="column" alignItems="center" justifyContent="space-between" mb={2}>
          {/* <Container> */}
          <Typography variant="h4" gutterBottom textAlign="center">
            Список заданий для точки {lineId}
          </Typography>

          {/* </Container> */}
        </Stack>

        {/* <img src={tasks[0].img} /> */}

      </Container>

    <Grid container>


        <Grid xs={12}>
        {
            tasks.map(
                task => 
                <Grid item xs={12}> 
                    <ConvertTask task={task} tasks={tasks}/>
                </Grid>)
        }

        </Grid>

        <Grid xs={12}>
        <Button 
             variant="contained"
             color="error"
              onClick = {
                () => {
                    console.log('convert ok');
                    const url = ApiServer.api + "/api/set_convert.php?line_id=" + lineId;
                    getDataJson(url, show);  
                }
            }
            disabled = {checkConvertStatus(tasks)}
            >
              Конвертация выполнена верно
            </Button>

        </Grid>
    </Grid>

    </Page>
  );
}