import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';
import {
  Card, Stack, Box, Container,
  Typography, Button, Grid, Paper, TextField
} from '@mui/material';

import { ApiServer } from ".././settings"
// components
import MaterialReactTable from 'material-react-table';
import { YMaps, Map, GeoObject, Placemark, RulerControl } from "react-yandex-maps";
import Page from '../components/Page';

import getDataJson from 'src/components/common_components/getDataJson';

import { getUrlLineTaskSum } from 'src/components/table_users/TableUsersLineTaskSum';
import { getUrlUserDateTasks } from 'src/components/table_users/TaskDialog';
import { getColorCategory, getColorTask } from './MapYandex';
import TaskView from 'src/components/searcher_components/taskView';
import LineInfoTableComponent from 'src/components/control/LineInfo';

export function getURLVarArr() {
  var data = [];
  var query = String(document.location.href).split('?');
  if (query[1]) {
    var part = query[1].split('&');
    for (let i = 0; i < part.length; i++) {
      var dat = part[i].split('=');
      data[dat[0]] = dat[1];
    }
  }
  return data;
}

const DayButton = (props) => {

  const click = () => {
    props.setDateFunction(props.task_date)
  }

  return (
    <Button sx={{ mt: 1, mb: 1 }} variant="contained" size="small" onClick={click}>
      {props.task_date}
    </Button>
  );
}

const TableDayTasks = (props) => {

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: 'line_id', //access nested data with dot notation
      //   header: '№',
      //   size: 10,
      // },
      {
        accessorKey: 'time',
        header: 'Время съемки',
      },
      // {
      //   accessorKey: 'task_status',
      //   header: 'Cтатус',
      //   size: 10,
      // },
      // {
      //   accessorKey: 'task_title',
      //   header: '№ задания',
      //   size: 10,
      // },
      
    ],
    [],
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={props.data}
      enableRowActions
      enablePinning={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}

      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}

      renderRowActions={({ row }) => (
        <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5rem' }}>
          <Button variant="contained"
            color="primary"
            onClick={() => {
              props.setLineId(row.original.line_id);
              console.info('Info', row.original.task_title);
              //  props.deleteLineDateTask(row.original.line_id);
              props.setInfo(row.original.task_title);

            }}
          >
            {row.original.line_id}
          </Button>

        </div>
      )}
    />
  );
}




const TableErrorFiles = (props) => {

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: 'line_id', //access nested data with dot notation
      //   header: 'Номер отрезка',
      // },
      // {
      //   accessorKey: 'time',
      //   header: 'Время съемки',
      // },

      {
        accessorKey: 'videofiles_id',
        header: 'Идентификатор файла',
      },
      {
        accessorKey: 'filename',
        header: 'Имя файла',
      },
      {
        accessorKey: 'files_status',
        header: 'Список ошибок',
      },
      {
        accessorKey: 'line_id',
        header: 'Номер отрезка',
      },
      {
        accessorKey: 'create_date',
        header: 'Начало съемки',
      },
      {
        accessorKey: 'time_length',
        header: 'Длина съемки, с',
      },
      {
        accessorKey: 'xg',
        header: 'Широта',
      },
      {
        accessorKey: 'yg',
        header: 'Долгота',
      },
    ],
    [],
  );

  return (
    <>
    <Typography color="error">Список файлов с ошибками</Typography>
    <MaterialReactTable
      columns={columns}
      data={props.datafiles}
      enableRowActions={false}
      enablePinning={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}

      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}

      // renderRowActions={({ row }) => (
      //   <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5rem' }}>
      //     <Button variant="contained"
      //       color="primary"
      //       onClick={() => {
      //         console.info('Info', row.original.task_title);
      //         //  props.deleteLineDateTask(row.original.line_id);
      //         props.setInfo(row.original.task_title);
      //       }}
      //     >
      //       Инфо
      //     </Button>

      //   </div>
      // )}
    />
    </>
  );
}

export default function User() {
  const getUserId = getURLVarArr().users_id;
  console.log("users_id=", getUserId);

  const [daySumLines, setDaySumLines] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  //  const [selectedTaskTitle, setSelectedTaskTitle] = useState("");
  const [points, setPoints] = useState([]);
  const [viewtask, setViewtask] = useState([]);
  const [datafiles, setDatafiles] = useState([]);
  const [lineId, setLineId] = useState("");

  useEffect(() => {
    console.log("User");
    getDataJson(getUrlLineTaskSum(getUserId), setDaySumLines);
    getDataJson(ApiServer.api + '/api/users_files.php?act=UsersErrorFiles&users_id=' + getUserId, setDatafiles);
    // props.users_id  
  }, []);

  useEffect(() => {
    console.log("New Day");
    getDataJson(getUrlUserDateTasks(getUserId, selectedDay), setPoints);
  }, [selectedDay]);


  // const onPlacemarkClick = point => () => {
  //   console.log("point", point );
  //   setSelectedPoint(point.line_id);
  // };

  const setInfo = (task_title) => {
    console.log(task_title);
    const url = ApiServer.api + "/api/api_task.php?taskTitle=" + task_title;
    getDataJson(url, setViewtask);
  }

  return (
<Page title="Maps">
      <Container>
        <Stack direction="column" alignItems="center" justifyContent="space-between" mb={2}>
          {/* <Container> */}
          <Typography variant="h4" gutterBottom textAlign="center">
            Список заданий
          </Typography>
          {/* </Container> */}
        </Stack>

      </Container>

      <Grid container spacing={2} height="100%" ml={2} >
        <Grid item xs={12} md={2}>
          <Grid container spacing={0.5} justifyContent="space-around">

          <Grid xs={12}>
          <Typography variant="h6" gutterBottom>
            Выберите дату
          </Typography>
          </Grid>

            {
              daySumLines.map((item) => <Grid xs={4} md={6}><DayButton setDateFunction={setSelectedDay} task_date={item.task_date} /></Grid>)
            }
          </Grid>
          </Grid>
          <Grid container xs={12} md={6} >
          

          <Grid item xs={12} >

          <Typography variant="h6" gutterBottom mt={3}>
          Точки измерения на {selectedDay}
          </Typography>
            

            <YMaps enterprise query={{ apikey: '6f09d219-95c2-4de5-af8a-b4f6356c216e' }}>
            <Map width="100%" height="60vh" defaultState={{  center:  [54.819694, 90.136340], zoom: 6}}>
              {/* <Map width="100%" height="60vh" defaultState={{ center: [55.751574, 37.573856], zoom: 9 }}> */}
                {points.map((coordinate, i) =>
                  <Placemark key={i}
                    geometry={coordinate.pos}
                    // onClick={onPlacemarkClick(coordinate)}
                    options={{
                      preset: getColorTask(0, getColorCategory(coordinate.category))
                    }}
                    // preset = 'islands#blueIcon'
                    properties={{
                      iconContent: 0,
                      hintContent: coordinate.category,
                      balloonContent: '№ ' + coordinate.line_id,
                      preset: 'islands#redSportIcon',
                    }}
                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                  />)}
                  <RulerControl />


              </Map>


            </YMaps>
          </Grid>
          <Grid item xs={12} >
          <Typography variant="h6" gutterBottom mt={3}>
            Инфо о заданиях на {selectedDay}
            </Typography>
            <TableDayTasks setInfo={setInfo} data={points} setLineId={setLineId}/>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* { 
              ({viewtask} === [] ) ? <TaskView task={viewtask} /> : <></>
            } */}
            <Card>
          <TaskView task={viewtask} />
          </Card>
        </Grid>
        <Grid item xs={12} md={8} >
          <TableErrorFiles datafiles={datafiles} />
        </Grid>
        
        <Grid item xs={12} md={12} >
        <Typography variant="h6" gutterBottom mt={3}>
            Общий список файлов для точки {lineId}
            </Typography>
          <LineInfoTableComponent line_id={lineId} />
        </Grid>
      </Grid>
    </Page>
  );
}
