import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';
import {
    Card, Stack, Box, Container, Link,
    Typography, Button, Grid, Paper, TextField, Fab
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { ApiServer } from ".././settings";
// components
import MaterialReactTable from 'material-react-table';
import { YMaps, Map, GeoObject, Placemark, RulerControl } from "react-yandex-maps";
import Page from '../components/Page';

import getDataJson from 'src/components/common_components/getDataJson';

import { faker } from '@faker-js/faker';
import { getUrlLineTaskSum } from 'src/components/table_users/TableUsersLineTaskSum';
import { getUrlUserDateTasks } from 'src/components/table_users/TaskDialog';
import { getColorCategory, getColorTask } from './MapYandex';
import { getURLVarArr } from './User';
import { getUrlTask } from 'src/components/table_users/TablePointTask';
import { getVideofilesByTaskUrl } from './Control';
import TaskView from 'src/components/searcher_components/taskView';
import { verifyArr } from 'src/components/control/FileInfo';
import InputWithButton from 'src/components/common_components/inputWithButton';
import { show } from './Control';
import { countBy } from 'lodash';
import { LocalLaundryService } from '@mui/icons-material';

import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
  } from '../sections/@dashboard/app';


export function getUserTasksUrl(){
    return ApiServer.api + "/api/api_count.php?act=getUserTasks";
}

export function getRandomUrlTask() {
    return ApiServer.api + '/api/users_task.php?&yamap=1&random=random';
}

const CountButtonGroup = (props) => {
    const disableModify = props.disableModify;
    // const [value, setValue] = useState(props.value_start);
    const [value, setValue] = useState(props.value);
    const [disabled, setDisabled] = useState(true);

    const autoTitle = props.autoTitle;
    console.log("CountButtonGroup",props.value_start);
    const changeValue = (d) => {
        //  console.log("pre-val=", value);
        // const newVal = parseInt(value) + d;
        const newVal = parseInt(document.getElementById(props.id).value) + d;
        // console.log("newVal=", newVal);
        document.getElementById(props.id).value = parseInt(newVal);
        setValue(newVal);
    };

    return (
        <Stack direction="column" alignItems="center" spacing={1}>
            <Typography variant="h6">
                {autoTitle}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>

                <Fab color="primary" aria-label="add" disabled={disabled}
                    onClick={() => {
                        changeValue(1);
                    }
                    }
                >
                    <AddIcon />
                </Fab>
                <TextField
                    id={props.id}
                    //  label="Number"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    defaultValue={props.value_start}
                    value =  {props.value_start}
                    width="5%"
                    disabled={disabled}
                    onChange={event => setValue(event.target.value)}
                />
                <Fab color="primary" aria-label="remove" disabled={disabled}
                    onClick={() => {
                        changeValue(-1);
                    }
                    }
                >
                    <RemoveIcon />
                </Fab>
                <Fab color="secondary" aria-label="edit" disabled={disableModify}
                    onClick={() => { setDisabled(!disabled) }}
                >
                    <EditIcon />
                </Fab>
            </Stack>
        </Stack>

    );
}

export function SimpleTextInput({ inputValue = "", inputText = "", changeFunction = f => f }) {
    return (
        <TextField
            value={inputValue}
            placeholder={inputText}
            onChange={event => {
                let text = event.target.value;
                changeFunction(text)}}
            type="text"
            multiline
            rows={2}
            required
        />
    );
}

function getVideofilesData(videofiles_id) {
    let data = [];

    for (var i = 1; i < 6; i++) {
        data[i] = document.getElementById(videofiles_id + i).value;
    }
    return data;
}

function setVideofilesData(videofiles_id, amounts) {
    // let data = [];

    for (var i = 1; i < 6; i++) {
        // data[i] = document.getElementById(videofiles_id + i).value;
        document.getElementById(videofiles_id + i).value = amounts[i];
    }
    // return data;
}

function sendVideofilesData(task_title, videofiles_id, data, note, dir){

    const dataJson = JSON.stringify(data);
    const dataBase64 = btoa(dataJson); 

    const encodedNote = encodeURIComponent(note);

    const url = ApiServer.api + "/api/api_count.php?act=saveVideofilesData&videofilesData=" + dataBase64 + "&taskTitle=" + task_title + "&videofilesId=" + videofiles_id + "&dir=" + dir + "&note=" + encodedNote;
    getDataJson(url, ()=>{});
}

export function getDirSymb(dir) {
    let symb = <ArrowBackIcon />
    if ((dir == 1) || (dir == true)) symb = <ArrowForwardIcon />
    return symb;
}

const PrevDataView = (props) => {
    //  console.log("PrevDataView", props)
    return (
        props.data.map(item => {
            //console.log("item=", item);
            return (
                <Typography>{getDirSymb(item.dir)}   {item.note}</Typography>
            );
        }
        )
    );
}

const CountFile = (props) => {

    const [speed, setSpeed] = useState(1);
    const [note, setNote] = useState("");
    const [dir, setDir] = useState(0);
    const [dirDisable, setDirDisable] = useState(0);
    // const [auto1, setAuto1] = useState(10);
    const [defAmount, setDefAmount] = useState([0,0,0,0,0,0]);
    const [errorText, setErrorText] = useState({'error': ''});

    //  const [prevData, setPrevData] = useState([{ note: "При подсчете первая автомашина - автобус", dir: true }, { note: "При подсчете первая автомашина - трактор", dir: false }]); //{note: "", dir: ""}
    //  const [countData, setCountData] = useState({countTaskId: 0, countData: []});

    function sendVideofilesData(task_title1, videofiles_id1, data1, note1, dir1){

        const dataJson = JSON.stringify(data1);
        const dataBase64 = btoa(dataJson); 
    
        const encodedNote = encodeURIComponent(note1);
    
        const url = ApiServer.api + "/api/api_count.php?act=saveVideofilesData&videofilesData=" + dataBase64 + "&taskTitle=" + task_title1 + "&videofilesId=" + videofiles_id1 + "&dir=" + dir1 + "&note=" + encodedNote;
        getDataJson(url, setErrorText);
    }



    const saveDataFile = () => {
        const data = getVideofilesData(props.videofiles_id);
        console.log("videofileData=", data);
        let sendingDir = dir ? 1 :0 ;
        sendVideofilesData(props.task_title, props.videofiles_id, data, note, sendingDir);
    }

    //const fileLink = "/static/convertedVideo/" + props.line_id + "/" + props.videofiles_id + ".mp4";
    
    
    //const fileLink = 'http://79.120.39.75:82/' + props.line_id + '/' + props.videofiles_id + '.mp4';
    const fileLink = "/convertedVideos/" + props.line_id + '/' + props.videofiles_id + '.mp4';

    // const task_title = props.task.task_title;
    // const task_date = props.task.task_date;
    // const fio = props.task.fio;
    // const task_status = props.task.task_status;

    // const [videofiles, setVideofiles] = useState({files_info: []});

    // useEffect(() => {
    //     console.log("Videofiles for task");
    //     getDataJson(getVideofilesByTaskUrl(task_title), setVideofiles);
    //   }, [props.tasks]);

    // console.log("ContFile", props);

    useEffect(() => {
            console.log("SetDefValues");
            setDefValues();
            // setAuto1(4);
          }, []);

    function setDefs(props1){
        setNote(props1.note);
        // console.log("setDefs",props1);
        setDir(props1.dir);
        setDirDisable(props1.dirDisable);
        // console.log("amounts=", props1.amounts);
        setDefAmount(props1.amounts);
        setVideofilesData(props.videofiles_id, props1.amounts);
        // setAuto1(props1.amounts[2]);
        console.log();
    }

    function setDefValues(){
        const url = ApiServer.api + "/api/api_count.php?act=getDefValues&videofilesId=" + props.videofiles_id + "&taskTitle=" + props.task_title;
        getDataJson(url, setDefs);
    };

    // console.log("defAmount=", defAmount);
    // setAuto1(4);
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Файл {props.videofiles_id}
                </Typography>

            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                    {/* <Grid item xs={12}> */}


                        <Box sx={{
                            bgcolor: 'background.paper',
                            boxShadow: 1,
                            borderRadius: 2,
                            p: 2,
                            mb: 10
                        }}>
                            <video width="100%" src={fileLink} id={props.videofiles_id} crossorigin="anonymous" controls></video> 
                            {/* <video width="100%" src="/static/convertedVideo/10011/160403.mp4" id={props.videofiles_id} controls></video> */}
                            {/* <video width="100%" src="/static/convertedVideo/10011/156225.mp4" id={props.videofiles_id}></video> */}
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> */}
                            <Stack direction="row" alignItems="space-between" justifyContent="space-between">
                                <Typography variant="h5" >{getDirSymb(dir)}</Typography>
                                <Typography variant="h5" >{getDirSymb(dir)}</Typography>
                                <Typography variant="h5" >{getDirSymb(dir)}</Typography>
                                <Typography variant="h5" >{getDirSymb(dir)}</Typography>
                                <Typography variant="h5" >{getDirSymb(dir)}</Typography>
                                <Button variant="contained" disabled={dirDisable} onClick={() => setDir(!dir)}>Изменить направление подсчета</Button>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>

                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={
                                        () => {
                                            const newSpeed = speed + 0.5;
                                            // console.log('speed up');
                                            document.getElementById(props.videofiles_id).playbackRate = newSpeed;
                                            setSpeed(newSpeed);

                                        }
                                    }
                                >
                                    + 0,5
                                </Button>

                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={
                                        () => {
                                            if (speed > 0) {
                                                const newSpeed = speed - 0.5;
                                                // console.log('speed down');
                                                document.getElementById(props.videofiles_id).playbackRate = newSpeed;
                                                setSpeed(newSpeed);
                                            };

                                        }
                                    }
                                >
                                    - 0,5
                                </Button>

                                <Typography variant="h5" gutterBottom>
                                    Скорость видео: {speed}
                                </Typography>

                            </Stack>
                            <Stack mt={2}>
                                <SimpleTextInput
                                    inputValue={note}
                                    inputText="Комментарий"
                                    changeFunction={setNote}
                                />
                            </Stack>
                            {/* <Typography>Направление и комментарии предыдущих подсчетов:</Typography>
                            <Stack>
                                <PrevDataView data={prevData} />
                            </Stack> */}
                        </Box>
                    </Grid>

                    {/* <Grid item xs={12}> */}
                    <Grid item xs={4}>

                        <Stack direction="column" spacing={5}>
                            <Typography variant="h6" justifyContent='center' textAlign='center'>
                                Файл {props.videofiles_id}
                            </Typography>

                            <CountButtonGroup
                                disableModify={false}
                                value={defAmount[1]}
                                // value_start={parseInt(auto1)}
                                // value={0}
                                autoTitle="I - легковые автомобили"
                                // autoTitle={auto1}
                                id={props.videofiles_id + 1}
                            />
                            <CountButtonGroup
                                disableModify={false}
                                value={defAmount[2]}
                                // value={0}
                                autoTitle="	II - автофургоны и микроавтобусы до 3,5 тонн"
                                id={props.videofiles_id + 2}
                            />
                            <CountButtonGroup
                                disableModify={false}
                                value={defAmount[3]}
                                // value={0}
                                autoTitle="	III - грузовые автомобили от 3,5 до 12 тонн"
                                id={props.videofiles_id + 3}
                            />
                            <CountButtonGroup
                                disableModify={false}
                                value={defAmount[4]}
                                // value={0}
                                autoTitle="	IV - грузовые автомобили свыше 12 тонн "
                                id={props.videofiles_id + 4}
                            />
                            <CountButtonGroup
                                disableModify={false}
                                value={defAmount[5]}
                                // value={0}
                                autoTitle="	V - автобусы свыше 3,5 тонн"
                                id={props.videofiles_id + 5}
                            />
                            <>
                            <Button variant="contained" color="error" size="large" onClick={() => saveDataFile()}>
                                Сохранить данные для файла
                            </Button>                            
                            <Typography variant="h6" justifyContent='center' textAlign='center'>
                            {errorText.error}
                            </Typography>
                            </>
                        </Stack>

                    </Grid>
                </Grid>
            </Grid>
        </>
        // <Grid container>
        //     <Grid item xs={12}>
        //         <Typography variant="h5">
        //             {fio+" "}
        //             задание {task_title}
        //             {" "+task_status+" "}
        //             {task_date+" c "+props.task.time_mon_start+" до "+props.task.time_mon_stop+" и c "+props.task.time_evn_start+" до "+props.task.time_evn_stop} <br/>
        //         </Typography>
        //         <Link href={props.task.url}>
        //         Ссылка на координаты отрезка на Google картах
        //         </Link>
        //     </Grid>
        //     <Grid item xs={12}>
        //     {
        //     videofiles.files_info.map(
        //         videofile => 
        //             <ConvertVideofile videofile={videofile}/>
        //     )
        //     }
        //     </Grid>
        // </Grid>
    );
}


// function ShowCardComponent(showCard, taskFilesInfo){
//     if (showCard) return (
//         <>
//            {/* <img src={taskFilesInfo.task_info.img} />
//            <Link href={taskFilesInfo.task_info.url}>
//                Ссылка на координаты отрезка на Google картах
//            </Link> */}
//            <Typography></Typography>
//            </>
//     ); else return <></>
// }

export default function Count() {

    const [taskFilesInfo, setTaskFilesInfo] = useState({'task_info': [], 'files_info': [] });
    const [taskList, setTaskList] = useState([]);
    const [showCard, setShowCard] = useState(false);

    useEffect(() => {
        const url = ApiServer.api + "/api/api_count.php?act=getTask";
        getDataJson(url, setTaskFilesInfo);
        getDataJson(getUserTasksUrl(), setTaskList);
    }, []);

    useEffect(() => {
        getDataJson(getUserTasksUrl(), setTaskList);
    }, []);

    const showTaskInfo = (task_title) => {
        console.log("showTaskInfo", task_title);
        const url = ApiServer.api + "/api/api_count.php?act=getTask&taskTitle=" + task_title;
        getDataJson(url, setTaskFilesInfo);
    }

    const saveAllDataFile = (finish) => {
        // const files_info = taskFilesInfo.files_info;
        // const res = files_info.map(item => getVideofilesData(item.videofiles_id));
        // console.log(res);
        const url = ApiServer.api + "/api/api_count.php?act=sendTask&taskTitle=" + taskFilesInfo.task_info.task_title + '&finish=' + finish;
        getDataJson(url, () => {
            const url = ApiServer.api + "/api/api_count.php?act=getTask";
            getDataJson(url, setTaskFilesInfo);
            getDataJson(getUserTasksUrl(), setTaskList);

        });

    };

    return (
        <Page title="Count">
            <Grid container>
                

                <Grid xs={12}>

                {/* <Button variant="contained" onClick={() => setShowCard(!showCard)}>Показать отрезок на карте</Button>
                <ShowCardComponent showCard={showCard} taskFilesInfo={taskFilesInfo} /> */}
                
                </Grid>
                

                {/* <Grid item xs={12} md={6} lg={4}>
             <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                'main', 'primary', 'secondary', 'error'
              ]} 
            />
          </Grid> */}


                <Grid xs={12}>
                    <Stack direction="column">
                    <Typography variant="h4" gutterBottom>
                        Список заданий
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        {(taskList.length > 0) ? taskList[0].title : ""}
                    </Typography>
                    </Stack>

                    <Grid container>

                    {
                        taskList.map(item =>
                            <Grid xs = {3}>
                                <Button variant="contained" onClick= {() => {showTaskInfo(item.task_title)}} >{item.task_title}</Button>
                                <Typography>{item.description}</Typography>
                                <br/>
                            </Grid>
                        )
                    }
                    </Grid>



                </Grid>

                <Grid xs={12}>
                    <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>

                        <Typography variant="h4" gutterBottom>
                            Подсчет автомашин
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            Задание {taskFilesInfo.task_info.task_title}
                        </Typography>

                    </Stack>
                </Grid>

                {/* <Grid xs={8}>
                    <video width="100%" src={"/static/convertedVideo/9760/163861.mp4"} id="116787" controls></video> 
                </Grid>

                <Grid xs={8}>
                    <video width="100%" src={"https://www.youtube.com/watch?v=YqLjvdKa0-A"} id="116787" controls></video> 
                </Grid>

                <Grid xs={8}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/YqLjvdKa0-A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </Grid> */}

                <Grid xs={12}>
                    {
                        taskFilesInfo.files_info.map(item =>

                            <CountFile key={faker.datatype.uuid()} task_title= {taskFilesInfo.task_info.task_title} { ...item}/>
                        )
                    }
                </Grid>

                <Grid xs={12}>
                    <Stack justifyContent='center' direction='row'>
                    {/* <Button variant="contained" size="large"  style={{width: '50%', backgroundColor: 'gray'}} onClick={() => saveAllDataFile(0)}>
                            ОТПРАВИТЬ НА ПРОВЕРКУ И ЗАВЕРШИТЬ ПОДСЧЕТЫ
                        </Button>   */}
                        <Button variant="contained" size="large" style={{width: '100%'}} onClick={() => saveAllDataFile(1)}>
                            ОТПРАВИТЬ НА ПРОВЕРКУ И ПОЛУЧИТЬ НОВОЕ ЗАДАНИЕ
                        </Button>   
                    </Stack>
                    <Typography variant="h5" color="error" gutterBottom justifyContent='center' textAlign='center'>
                            Проверьте, что данные по каждому файлу сохранены!
                    </Typography>
                </Grid>

                <Grid xs={12}>

                    <Typography variant="h5" gutterBottom>
                        Отрезок на карте
                    </Typography>
                    <img src={taskFilesInfo.task_info.img} />
                    <Link href={taskFilesInfo.task_info.url}>
                        Ссылка на координаты отрезка на Google картах
                    </Link>
                </Grid>

            </Grid>
        </Page>
    );
}