import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';
import {
    Card, Stack, Box, Container, Link,
    Typography, Button, Grid, Paper, TextField, Fab
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { ApiServer } from ".././settings";

import Page from '../components/Page';

import getDataJson from 'src/components/common_components/getDataJson';
import InputWithButton from 'src/components/common_components/inputWithButton';


export function getUserTasksUrl() {
    return ApiServer.api + "/api/api_count.php?act=getUserTasks";
}



export default function CountAdminMain() {

    const [taskList, setTaskList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getDataJson(getUserTasksUrl(), setTaskList);
    }, []);

    return (
        <Page title="Count">
            <Grid container>


                <Grid xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Список подсчетов для контроля
                    </Typography>

                    <InputWithButton inputFunction={
                        (e) => {
                            console.log(e);
                            e = encodeURI(e);
                            console.log(e);
                            navigate('/count_admin_task?task_title=' + e, { replace: true });

                        }}
                        inputString="введите номер задания ..."
                        buttonString="Открыть"
                    />

                    <Grid container>

                        {
                            taskList.map(item =>
                                <Grid xs={2}>
                                    <Button variant="contained" onClick={() => { navigate('/count_admin_task?task_title=' + item.task_title, { replace: true }); }} >{item.task_title}</Button>
                                    <Typography>{item.description}</Typography>
                                    <Typography>Принято {item.task_correct_params ? item.task_correct_params : 0}</Typography>
                                    <br />
                                </Grid>
                            )
                        }
                    </Grid>


                </Grid>


            </Grid>
        </Page>
    );
}