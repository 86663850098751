// material
import { alpha, useTheme } from '@mui/material/styles';
import { GlobalStyles,Button, Typography, Container, Link } from '@mui/material';
import React, { useState, useEffect,useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { PropaneSharp } from '@mui/icons-material';
import { array } from 'prop-types';
import getDataJson from '../common_components/getDataJson';
import {ApiServer} from "../.././settings";

// ----------------------------------------------------------------------
export function getUrlTask(line_id) {
  return ApiServer.api + '/api/users_task.php?line_id='+line_id+'&yamap=1';
}

export function getUrlLineView(line_id) {
  return ApiServer.api + '/api/api_line.php?line_id=' + line_id;
}

function getUrlDeletePointTask(line_id, task_date) {
  return ApiServer.api + '/api/users_task.php?line_id='+line_id+'&task_date='+task_date+'&delete=del';
}

function getUrlDeleteAllPointTasks(line_id) {
  return ApiServer.api + '/api/users_task.php?line_id='+line_id+'&delete=all';
}

const TablePointTaskStart = (props) => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'task_title',
        header: 'Номер задания',
      },
      {
        accessorKey: 'fio', //access nested data with dot notation
        header: 'ФИО',
      },
      {
        accessorKey: 'task_date',
        header: 'Дата',
      },

      // {
      //   accessorKey: 'task_status',
      //   header: 'Статус',
      // },
      // {
      //   accessorKey: 'users_id', //normal accessorKey
      //   header: '№',
      // },
    ],
    [],
  );

  return <MaterialReactTable 
          columns={columns} 
          data={props.data} 
          enableRowActions
          // enableStickyFooter 
          enablePinning={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}

          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting={false}
          

          renderRowActions={({ row }) => (
            <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5rem' }}>       
            <Button          variant="contained"
                 color="primary"
                 onClick={() => {
                   console.info('Delete', row.original.task_date);
                   props.deletePointTask(row.original.task_date);
                   
                 }}
               >
                Удалить
               </Button>
            
             </div>
           )}
    />;
};




export default function TablePointTask(props) {
  const theme = useTheme();

  const background = {
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72)
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("TablePointTask", props); 
    getDataJson(getUrlTask(props.line_id),setData);
 },[props.line_id]);

 const deletePointTask = (task_date) => {
    getDataJson(getUrlDeletePointTask(props.line_id, task_date), setData);
    props.refreshMap(props.usersPointsMode);
}

  const deleteAllPointTasks = () => {
    getDataJson(getUrlDeleteAllPointTasks(props.line_id), setData);
  }

  const [dataLine, setDataLine] = useState({});

  useEffect(() => {
      getDataJson(getUrlLineView(props.line_id), setDataLine);
  }, [props.line_id]);

  return <div>
        <Typography variant="h6">
        Задания по точке {props.line_id}
    </Typography>
     
    <Button          variant="contained"
                 color="primary"
                 onClick={() => {
                  console.info('DeleteAll', props.line_id);
                  //  props.data.map((item) => {
                  //       console.info('item', item);
                  //       // Удалить задание
                  //  })
                  // //  props.view(row.original.users_id);
                  deleteAllPointTasks();
                  props.refreshMap(props.usersPointsMode);
                 }}
               >
                Удалить все задания
               </Button>

            <TablePointTaskStart data={data} deletePointTask={deletePointTask}/>
            <Container>
        {((typeof dataLine.title) !== 'undefined') ?
        <>

        <Typography variant="h6" gutterBottom>
            Отрезок для измерения "{dataLine.title}" <br />
            Категория: {dataLine.category}

        </Typography>

        <Typography variant="body1" gutterBottom>
            Измерение проводится: с {dataLine.time_mon_start} до {dataLine.time_mon_stop} утром <br />
            и с {dataLine.time_evn_start} до {dataLine.time_evn_stop} вечером <br />

            <Link href={dataLine.url}>
            Ссылка на координаты отрезка на Google картах
            </Link>
            <img src={dataLine.img} />
        </Typography>
        </> : <></>
}
    </Container>
      </div>

  // return <div>TableUsers <BasicDateRangePicker/></div>
}
