// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'Карта',
    path: '/dashboard/map',
    icon: getIcon('eva:map-fill'),
  },
  {
    title: 'Посмотреть задание',
    path: '/dashboard/task',
    icon: getIcon('eva:pie-chart-2-fill'),
  },

  // {
  //   title: 'Control',
  //   path: '/dashboard/control',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'Проверить видеофайл по координате',
    path: '/dashboard/video',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Статистика',
    path: '/dashboard/report',
    icon: getIcon('eva:layers-outline'),
  },
  // {
  //   title: 'Проверить задания',
  //   path: '/dashboard/convert',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'Посмотреть файлы задания',
    path: '/dashboard/control',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Посмотреть файлы точки',
    path: '/dashboard/line_control',
    icon: getIcon('eva:eye-outline'),
  },
  {
    title: 'Проверить видеофайл',
    path: '/dashboard/file_control',
    icon: getIcon('eva:video-fill'),
  },
  {
    title: 'Неверифицированные видеофайлы',
    path: '/dashboard/bad_videos',
    icon: getIcon('eva:alert-triangle-outline')
  },
  {
    title: 'Сводка по городу',
    path: '/dashboard/report_city',
    icon: getIcon('eva:activity-outline')
  },
  // {
  //   title: 'Count',
  //   path: '/dashboard/count',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  // {
  //   title: 'city',
  //   path: '/dashboard/city',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  // {
  //   title: 'user',
  //   path: '/user',
  //   icon: getIcon('eva:people-fill'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  {
    title: 'выход',
    path: '/login',
    icon: getIcon('eva:lock-fill'),
  },
  {
    title: 'регистрация',
    path: '/register',
    icon: getIcon('eva:person-add-fill'),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
