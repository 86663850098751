import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, TextField
  } from '@mui/material';
  
  import { ApiServer } from ".././settings"
  // components
  import MaterialReactTable from 'material-react-table';
  import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
  import { YMaps, Map, GeoObject, Placemark, RulerControl } from "react-yandex-maps";
  import Page from '../components/Page';
  
  import getDataJson from 'src/components/common_components/getDataJson';
  
  import { getUrlLineTaskSum } from 'src/components/table_users/TableUsersLineTaskSum';
  import { getUrlUserDateTasks } from 'src/components/table_users/TaskDialog';
  import { getColorCategory, getColorTask } from './MapYandex';
  import TaskView from 'src/components/searcher_components/taskView';
  import { getUrlCity } from './MapYandex';
  import { getURLVarArr } from './User';
  import VisibilityIcon from '@mui/icons-material/Visibility';

import { useDateRangePickerDefaultizedProps } from '@mui/x-date-pickers-pro/DateRangePicker/shared';
import CityProgressTable from 'src/components/report/CityProgressTable';

  
  export default function Progress() {

    return (
        // <Page title="Progress">
            // <Container>
              <Grid container spacing={4} sx={{pl:2, pr:2}}>
                <CityProgressTable city="Новокузнецк"/>
                <CityProgressTable city="Красноярск"/>
                <CityProgressTable city="Нижний Тагил"/>
                <CityProgressTable city="Норильск"/>
                <CityProgressTable city="Омск"/>
                <CityProgressTable city="Ростов-на-Дону"/>
                <CityProgressTable city="Новочеркасск"/>
                <CityProgressTable city="Искитим"/>
                <CityProgressTable city="Братск"/>
                <CityProgressTable city="Медногорск"/>
                <CityProgressTable city="Ангарск"/>
                <CityProgressTable city="Зима"/>
                <CityProgressTable city="Иркутск"/>
                <CityProgressTable city="Свирск"/>
                <CityProgressTable city="Усолье-Сибирское"/>
                <CityProgressTable city="Черемхово"/>
                <CityProgressTable city="Шелехов"/>
                <CityProgressTable city="Кемерово"/>
                <CityProgressTable city="Чита"/>
                <CityProgressTable city="Улан-Удэ"/>
                <CityProgressTable city="Кызыл"/>
                <CityProgressTable city="Абакан"/>
                <CityProgressTable city="Минусинск"/>
                <CityProgressTable city="Черногорск"/>
                <CityProgressTable city="Ачинск"/>
                <CityProgressTable city="Лесосибирск"/>
                <CityProgressTable city="Петровск-Забайкальский"/>
                <CityProgressTable city="Селенгинск"/>
                <CityProgressTable city="Гусиноозерск"/>
                <CityProgressTable city="Курган"/>
                <CityProgressTable city="Южно-Сахалинск"/>
                <CityProgressTable city="Барнаул"/>
                <CityProgressTable city="Уссурийск"/>
                <CityProgressTable city="Комсомольск-на-Амуре"/>
                <CityProgressTable city="Чегдомын"/>
                </Grid>
            // </Container>
      // </Page>
    );
  }