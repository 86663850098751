import React, { useState, useEffect } from 'react';
import { Navigate, useRoutes, Routes, Route,useNavigate } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Convert from './pages/Convert';
import Count from './pages/Count';
import Login from './pages/Login';
import Control from './pages/Control';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
//  import City from './pages/City';
import DashboardApp from './pages/DashboardApp';
import Task from './pages/Task';
//  import City from './pages/CityCard';
import Video from './pages/Video';
import MapYandex from './pages/MapYandex';
import getDataJson,{getDataJsonToken} from 'src/components/common_components/getDataJson';
import {ApiServer} from "src/settings";
import { Context, FormatDate } from "src/Context";
import CityView from './pages/CityView';
import CityViewOperator from './pages/CityViewOperator';
import Report from './pages/Report';
import CountAdminMain from './pages/CountAdminMain';
import CountAdminTask from './pages/CountAdminTask';
import Statistics from './pages/Statistics';
import FileControl from './pages/FileControl';
import Reportnew from './pages/Reportnew';
import LineControl from './pages/LineControl';
import Progress from './pages/Progress';
import Accept from './pages/Accept';
import Allcityfiles from './pages/Allcityfiles';
import BadVideos from './pages/BadVideos'
import ReportCity from "./pages/ReportCity";

// ----------------------------------------------------------------------

export function setCookie(name, value, options = {}) { //in js

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}


function deleteCookie(name) { //in js
  setCookie(name, "", {
    'max-age': -1
  })
}

function getCookie(name) { //in js
  console.log("in getCookie");
  console.log(document.cookie);
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  console.log(matches);
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookies() { //server
  getDataJson(ApiServer.api +"/api/clean_cookies.php", console.log); 
}


export default function Router() {
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useState(0);
  //  const userStatus = getCookie("air_status"); // 0 - не верифицирован, 1 - исполнитель, 2 - раздаватель
  // console.log("Start router userStatus = ",userStatus);
  const stat = getCookie("air_status");
  console.log("Air_status = ", stat);

  // useEffect(() => {
  //   console.log("Router");
  //   const path = document.location.pathname;
  //   console.log("path=", path);
  //   if ((path == '/login') || (path == '/register')) {
  //     //  deleteCookie("air_token");
  //     //  deleteCookie("air_status");
  //   } else {
  //     console.log("air_status=", getCookie("air_status"));
  //     if (getCookie("air_token") && getCookie("air_status")) setUserStatus(getCookie("air_status"));
  //   };
    
    // get afterCheckTocken

  //});
  
  useEffect(() => {
    console.log("Router useEffect");
    const path = document.location.pathname;
    if ((path == '/login') || (path == '/register')) {
        deleteCookies();
    }
    else {
      console.log("checkUserAccess");
      // let token = Context.token;
      getDataJson(ApiServer.api +"/api/checktoken.php", checkUserAccess); 
      getDataJsonToken(ApiServer.api +"/api/checktoken.php", Context.token, checkUserAccess)
    }
  })


function checkUserAccess(paramJson) {
  console.log('paramJson=',paramJson);

  //  if (!(paramJson.status == getCookie("air_status"))) {
  if (paramJson.status == -1) {
    navigate('/login', { replace: true });
  }
  
}

    let resRouter = [
      {
        path: '/',
        element: <LogoOnlyLayout />,
        children: [
          { path: '/', element: <Navigate to="/login" /> },
          { path: 'login', element: <Login setUserStatus={setUserStatus}/> },
          { path: 'register', element: <Register /> },
          { path: '404', element: <NotFound /> },
          { path: '*', element: <Navigate to="/404" /> },
          { path: 'user', element: <User /> },
          { path: 'count', element: <Count /> },
          { path: 'count_admin_main', element: <CountAdminMain /> },
          { path: 'count_admin_task', element: <CountAdminTask /> },
          { path: 'accept', element: <Accept /> },
        ],
      },
      { path: '*', element: <Navigate to="/404" replace /> },
    ];

    if (userStatus == 0) {
      resRouter = [
        {
          path: '/',
          element: <LogoOnlyLayout />,
          children: [
            { path: '/', element: <Navigate to="/login" /> },
            { path: 'login', element: <Login setUserStatus={setUserStatus}/> },
            { path: 'register', element: <Register /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> },
            { path: 'user', element: <User /> },
            { path: 'city', element: <CityView /> },
            { path: 'cityoperator', element: <CityViewOperator /> },
            { path: 'statistics', element: <Statistics /> },
            { path: 'progress', element: <Progress /> },
            { path: 'allcityfiles', element: <Allcityfiles /> },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
      ]
    };

    if (userStatus == 2) {
      resRouter = [
          {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
              { path: 'app', element: <DashboardApp /> },
              { path: 'task', element: <Task /> },
              { path: 'control', element: <Control /> },
              { path: 'video', element: <Video /> },
              { path: 'map', element: <MapYandex /> },
              { path: 'file_control', element: <FileControl /> },
              { path: 'line_control', element: <LineControl /> },
              { path: 'report', element: <Reportnew /> },
              { path: 'city', element: <CityView /> },
              { path: 'products', element: <Products /> },
              { path: 'blog', element: <Blog /> },
              { path: 'convert', element: <Convert /> },
              { path: 'count', element: <Count /> },
              { path: 'count_admin_main', element: <CountAdminMain /> },
              { path: 'count_admin_task', element: <CountAdminTask /> },
              { path: 'allcityfiles', element: <Allcityfiles /> },
              { path: 'bad_videos', element: <BadVideos />},
              { path: 'report_city', element: <ReportCity />}
            ],
          },
          {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
              { path: '/', element: <Navigate to="/dashboard/map" /> },
              { path: 'login', element: <Login setUserStatus={setUserStatus}/> },
              { path: 'register', element: <Register /> },
              { path: '404', element: <NotFound /> },
              { path: '*', element: <Navigate to="/dashboard/map" /> },
              { path: 'user', element: <User /> },
            ],
          },
          { path: '*', element: <Navigate to="/dashboard/map" replace/> },
        ]
    };
    console.log("resRouter",resRouter);
    console.log("userStatus",userStatus);
    return useRoutes(resRouter);

}
