import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, TextField
  } from '@mui/material';
  import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
  import { ApiServer } from "../.././settings"
  // components
  import MaterialReactTable from 'material-react-table';
  // import { MRT_Localization_RU } from 'material-react-table/locales';
  import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
  
  import getDataJson from 'src/components/common_components/getDataJson';


  const TableAllFiles = (props) => {

    const columns = useMemo(
      () => [
        {
            accessorKey: 'city', //access nested data with dot notation
            header: 'Город',
            //size: auto,
        },
        {
            accessorKey: 'line_id',
            header: 'Id отрезка',
            size: 30,
        },
        {
            accessorKey: 'videofiles_id',
            header: 'Id файла',
            size: 30,
        },
        {
            accessorKey: 'mag_number',
            header: '№ магистрали',
            size: 30,
        },
        {
            accessorKey: 'mag_name',
            header: 'Название магистрали',
            //size: auto,
        },
        {
            accessorKey: 'number',
            header: 'Номер отрезка',
            size: 30,
        },
        {
            accessorKey: 'title',
            header: 'Название отрезка',
            size: 500,
        },
        {
            accessorKey: 'exportfilename',
            header: 'Имя экспортированного файла',
            size: 300,
        },
        {
            accessorKey: 'exported_datetime',
            header: 'Дата выгрузки',
            //size: auto,
        },
        {
            accessorKey: 'accepted',
            header: 'Принят заказчиком',
            size: 30,
        },
        // {
        //     accessorKey: 'procent',
        //     header: 'Процент',
        //   //   size: 10,
        //   }
      ],
      [],
    );
  
    return (
<MaterialReactTable
        columns={columns}
        data={props.data}
        //enableRowActions={true}
        enablePinning={false}
        // enableTopToolbar={false}
        // enableBottomToolbar={false}
  
        // enableColumnActions={false}
        enableColumnFilters={true}
        enablePagination={false}
        enableSorting={true}
        // localization={MRT_Localization_RU}
        muiTableBodyProps={{
          sx: () => ({
            '& tr:nth-child(2n+1)': {
              backgroundColor: 'silver'
            }
          })
        }}
        
        muiTableBodyRowProps={{
          sx: {
            height: '10px',
            
          }
        }} muiTableBodyCellProps={{
          sx: {
            p: '2px 16px'
          }
        }}

      />
    );
  }

  
  export default function AllFilesTable(props) {
    const [statLine, setStatLine] = useState([]);
  
    useEffect(() => {
        const url = ApiServer.api + '/api/report.php?title=allfiles&city=' + props.city;
        getDataJson(url, setStatLine); 
    }, []);

    return (
        <div style={{paddingBottom: "50px", paddingTop: "100px", paddingLeft: "15px"}}>
              <TableAllFiles data={statLine}/>
        </div>
    );
  }