import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, TextField
} from '@mui/material';

import { ApiServer } from ".././settings"
// components
import MaterialReactTable from 'material-react-table';
import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { YMaps, Map, GeoObject, Placemark, RulerControl } from "react-yandex-maps";
import Page from '../components/Page';

import getDataJson from 'src/components/common_components/getDataJson';

import { getUrlLineTaskSum } from 'src/components/table_users/TableUsersLineTaskSum';
import { getUrlUserDateTasks } from 'src/components/table_users/TaskDialog';
import { getColorCategory, getColorTask } from './MapYandex';
import TaskView from 'src/components/searcher_components/taskView';
import { getUrlCity } from './MapYandex';
import { getURLVarArr } from './User';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useDateRangePickerDefaultizedProps } from '@mui/x-date-pickers-pro/DateRangePicker/shared';
import CityProgressTable from 'src/components/report/CityProgressTable';
import {Context} from "../Context";
import ReportCityTable from 'src/components/report/ReportCityTable';


export default function ReportCity() {
    const city = Context.city;
    return (
        <ReportCityTable city={city}/>
    );
}