import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import getDataJson from '../common_components/getDataJson';
import {ApiServer} from "../../settings";
import {Context, FormatDate} from "src/Context";

export default function LineDialog(props) {
    const {onClose, open, line_id, setStatLine} = props;

    const handleClose = () => {
        onClose();
    };

    const setParamLine = (param, status) => {
        const city = Context.city;
        const url = ApiServer.api + '/api/api_set_line.php?line_id=' + line_id + '&param=' + param + '&status=' + status + '&city=' + city;
        getDataJson(url, setStatLine);
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={false}>
            <DialogTitle>Отрезок {line_id}</DialogTitle>
            <Container>
                <div>
                    <Button sx={{width: 200, padding: 1, margin: 2}} variant="contained" onClick={() => {
                        setParamLine('paid', 1)
                    }}>
                        Оплачен
                    </Button>
                    <Button sx={{width: 200, padding: 1, margin: 2}} variant="contained" onClick={() => {
                        setParamLine('paid', 0)
                    }}>
                        Не оплачен
                    </Button>
                </div>
            </Container>

        </Dialog>
    );
}
