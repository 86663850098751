export async function getDataJsonToken(url, token,callback) {
  console.log("getDataJsonToken");
  const response = await fetch(url+"?token="+token, {
    method: 'GET'
  });
  if (response.ok) {
    const json = await response.json();
    console.log("getDataJsonToken",json);
    callback(json);
  }
    
}

async function getDataJson(url, callback) {
    console.log("getDataJson",url);
    const response = await fetch(url); 
        if (response.ok) {  //   если HTTP-статус в диапазоне 200-299
          //  получаем тело ответа (см. про этот метод ниже)
          console.log("url",url);
          const json = await response.json();
          console.log("json",json);
          //  console.log("resJson",json);
          callback(json);
        } else {
          alert(`Ошибка HTTP: ${response.status}`);
        }
  }

export default getDataJson;