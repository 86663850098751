import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, TextField
  } from '@mui/material';
  
import { ApiServer } from 'src/settings';
  // components
  import MaterialReactTable from 'material-react-table';
  // import { MRT_Localization_RU } from 'material-react-table/locales';
  import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
  
  import getDataJson from 'src/components/common_components/getDataJson';

  const LineInfoTable = (props) => {

    const columns = useMemo(
      () => [
        {
           accessorKey: 'videofiles_id', //access nested data with dot notation
           header: 'Номер видеофайла',
        //    size: 10,
        },
        {
          accessorKey: 'filename',
          header: 'Название видеофайла',
        //   size: 10,
        },
        {
            accessorKey: 'info',
            header: 'Город, кто снимал, номер точки',
          //   size: 10,
          },
          {
            accessorKey: 'create_date',
            header: 'Дата и время съемки',
          //   size: 10,
          },
          {
            accessorKey: 'verify_title',
            header: 'Принят системой',
          //   size: 10,
          },
          {
            accessorKey: 'files_status',
            header: 'Ошибки',
          //   size: 10,
          },
          {
            accessorKey: 'converted_title',
            header: 'Обрезан',
          //   size: 10,
          },
          {
              accessorKey: 'eyechecked_title',
              header: 'Принят куратором',
              //   size: 10,
          },
          {
              accessorKey: 'moderator_comment',
              header: 'Комментарий куратора',
              //   size: 10,
          },
          {
            accessorKey: 'exported_title',
            header: 'Отправлен заказчику',
          //   size: 10,
          },
          {
            accessorKey: 'accepted_title',
            header: 'Принят заказчиком',
          //   size: 10,
          },

      ],
      [],
    );
  
    return (
      <MaterialReactTable
        columns={columns}
        data={props.data}
        // enableRowActions={false}
        enablePinning={false}
        // enableTopToolbar={false}
        // enableBottomToolbar={false}
  
        // enableColumnActions={false}
        enableColumnFilters={true}
        enablePagination={false}
        // enableSorting
        // localization={MRT_Localization_RU}
        muiTableBodyProps={{
          sx: () => ({
            '& tr:nth-child(2n+1)': {
              backgroundColor: 'silver'
            }
          })
        }}
        
        muiTableBodyRowProps={{
          sx: {
            height: '10px',
            
          }
        }} muiTableBodyCellProps={{
          sx: {
            p: '2px 16px'
          }
        }}

      />
    );
  }

  
  export default function LineInfoTableComponent(props) {
    const line_id = props.line_id;
    const [statLine, setStatLine] = useState([]);
  
    useEffect(() => {
        const url = ApiServer.api + '/api/report.php?title=lineinfo&line_id=' + line_id;
       getDataJson(url, setStatLine); 
    }, [line_id]);

    return (
            <LineInfoTable data={statLine} />
    );
  }
