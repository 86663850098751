import {now} from 'lodash';
import ReactDOM from 'react-dom/client';

export function FormatDate(d) {
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const monthmod = month.toString().padStart(2, '0');
    const daymod = day.toString().padStart(2, '0');
    return (year + '-' + monthmod + '-' + daymod);
}

export const Context = {
    title: "start",
    ver: "1.2.1",
    counter: 0,
    city: "",
    city_id: 0,
    air_status: 0,
    token: "",
    linesList: "",
    pointData: {
        line_id: 0,
        users_id: 0,
        users_title: "",
        dateStart: FormatDate(new Date()),
        dateEnd: FormatDate(new Date()),
    }
}
