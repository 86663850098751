import React, { useState, useEffect,createContext, useContext } from 'react';
import {
    Card, Stack, Box, Container,
    Typography, Button, Grid,Paper, listClasses, ListItem, List, Link
  } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import {ApiServer} from ".././settings"
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../components/Iconify';
import SearcherFragment from '../components/searcher_components/SearcherFragment';
  // components
import { YMaps, Map,  GeoObject, Placemark, ZoomControl, RulerControl  } from "react-yandex-maps";
import Page from '../components/Page';
import { faker } from '@faker-js/faker';

import getDataJson from 'src/components/common_components/getDataJson';
import InputWithButton from 'src/components/common_components/inputWithButton';
import FileInfo from 'src/components/control/FileInfo';
import { countBy } from 'lodash';
import { Context } from 'src/Context';

export const show = (str) => {console.log(str)};

export function getVideofilesByTaskUrl(task) {
  const url = ApiServer.api + "/api/api_task.php"+`?taskTitle=${task}`+"&act=control";
  return url;
}
  
  export default function Control() {

    const [taskFilesInfo, setTaskFilesInfo] = useState({'task_info': [], 'files_info': []});
    const t = taskFilesInfo.task_info;
    console.log(t);

    console.log('CITY=', Context.city);

    let taskHeader = <></>;
    if (t.task_title) {
      taskHeader = 
        <Container >
        <Typography variant="h5" gutterBottom mt={5}>
            Задание {t.task_title}  <br />
            {/* Состояние задания: {t.task_status} */}
        </Typography>

        <Typography variant="h6" gutterBottom>
            Отрезок для измерения "{t.street}" № {t.line_id} <br />
            Количество сторон: {t.side}
        </Typography>
            Исполнитель {t.fio} <br />
            Номер телефона: {t.tel} <br />

        <Typography variant="body1" gutterBottom>
            Дата измерения: {t.task_date} <br />
            Измерение проводится: с {t.time_mon_start} до {t.time_mon_stop} утром <br />
            и с {t.time_evn_start} до {t.time_evn_stop} вечером <br />

            <Link href={t.url}>
            Ссылка на координаты отрезка на Google картах
            </Link>
            <img src={t.img} />

        </Typography>
      </Container>
    };

    

    function checkStatus(filesList, side) { // менять статус задания на выполнено/не выполнено можно только если есть 4 хороших файла (verify 1)
      const succeessFiles = filesList.filter(file => file.verify == 1);
      console.log("succeessFiles=", succeessFiles);
      return ( succeessFiles.length != (side * 2) );
    }


    return (
      <Page title="Searcher">
        <Container>
          <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                Посмотреть задание
                </Typography>
    
                <Typography variant="h7" gutterBottom>
                Загруженные файлы
                </Typography>
          </Stack>
                <InputWithButton inputFunction = {
                    (e) => {console.log(e);
                    e = encodeURI(e);
                    console.log(e);
            
                    const url = getVideofilesByTaskUrl(e);
            
                    console.log("search url");
                    console.log(url);
                    getDataJson(url, setTaskFilesInfo);          
                     } } 
                    inputString = "ввести task_title ..." 
                    buttonString = "Загрузить"
            />
            <Container>
            {/* <Button 
            variant="contained"
            color="error"
            onClick = {
                () => {
                    console.log('click random');
                    const url = ApiServer.api + "/api/api_task.php?act=control&random=random&city=" + Context.city;
                    getDataJson(url, setTaskFilesInfo);  
                }
            }>
                Проверить случайное
            </Button> */}
            </Container>
             
            {taskHeader}
           

            <Container>
                {
                  


                taskFilesInfo.files_info.map(item => <FileInfo key={faker.datatype.uuid()} {... item} />)
                //  taskErrors.map(item => {console.log(item)})
                }
            </Container>

            <Box mt={3}>
            {/* <Stack direction="row" spacing={2}>
            <Button
            variant="contained"
            color="error"
            onClick = {
                () => {
                    console.log('task ok');
                    const url = ApiServer.api + "/api/api_task.php?act=status&status=4&taskTitle=" + t.task_title;
                    getDataJson(url, show);  
                }
            }
            disabled = {checkStatus(taskFilesInfo.files_info, t.side)}
            >
                Отметить как выполненное
            </Button>
            <Button 
             variant="contained"
             color="error"
            onClick = {
                () => {
                    console.log('task error');
                    const url = ApiServer.api + "/api/api_task.php?act=status&status=3&taskTitle=" + t.task_title;
                    getDataJson(url, show);  
                }
            }
            disabled = {checkStatus(taskFilesInfo.files_info, t.side)}
            
            >
                Отметить как невыполненное
            </Button>
            </Stack> */}
            </Box>
            </Container>
      </Page>
    );
  }