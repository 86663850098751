import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, TextField
  } from '@mui/material';
  
  import { ApiServer } from ".././settings"
  // components
  import MaterialReactTable from 'material-react-table';
  import React, { useState, useEffect,createContext, useContext } from 'react';
  import { YMaps, Map, GeoObject, Placemark, RulerControl } from "react-yandex-maps";
  import Page from '../components/Page';
  
  import getDataJson from 'src/components/common_components/getDataJson';
  
  import { getUrlLineTaskSum } from 'src/components/table_users/TableUsersLineTaskSum';
  import { getUrlUserDateTasks } from 'src/components/table_users/TaskDialog';
  import { getColorCategory, getColorTask } from './MapYandex';
  import TaskView from 'src/components/searcher_components/taskView';
  import { getUrlCity } from './MapYandex';
  import { getURLVarArr } from './User';
  
  // 53.40310215335972	58.991614600133445 magnitigorsk

  export function getColorTaskOperator(count_task, correct_count, color, line_id = null) {
    let res = color;
  
    if (count_task > 0) {
      res = 'islands#blueRepairShopCircleIcon';
      //  res = 'default#image';
    }

    if (correct_count == 5) {
        res = 'islands#violetGovernmentCircleIcon';
        //  res = 'default#image';
      }
 
    return res;
  }
  
  export default function CityView() {
    const varArr = getURLVarArr();
    console.log("varArr=", varArr);
    const getCity = decodeURI(getURLVarArr().city);
    console.log("city=", getCity);
    const [points, setPoints] = useState([]);
  
    let centerp = (getCity == "Магнитогорск") ? [53.40310215335972,	58.991614600133445] : [55.169990838849536,61.390001897265265];
    // if (getCity == "Магнитогорск") {
    //   centerp=[53.40310215335972,	58.991614600133445];
    // };
  
    useEffect(() => {
      //  console.log("In useEffect");
      getDataJson(getUrlCity(getCity), setPoints); 
    }, []);
  
  //   useEffect(() => {
  //     console.log("New Day");
  //     getDataJson(getUrlUserDateTasks(getUserId, selectedDay), setPoints);
  //   }, [selectedDay]);
  
  
  //   const setInfo = (task_title) => {
  //     console.log(task_title);
  //     const url = ApiServer.api + "/api/api_task.php?taskTitle=" + task_title;
  //     getDataJson(url, setViewtask);
  //   }
  
  //  select `l`.`line_id` AS `line_id`,`l`.`city` AS `city`,`l`.`category` AS `category`,`l`.`xg1` AS `xg1`,`l`.`xg2` AS `xg2`,`l`.`yg1` AS `yg1`,`l`.`yg2` AS `yg2`,count(`ut`.`line_id`) AS `count_task`,`vcc`.`correct_count` AS `correct_count` from ((`line` `l` left join `users_task` `ut` on(`l`.`line_id` = `ut`.`line_id`)) left join `v_correct_count` `vcc` on(`vcc`.`line_id` = `l`.`line_id`)) where `ut`.`users_task_status_id` <> 3 or `ut`.`users_task_status_id` is null group by `l`.`line_id`
  
    return (
          <Page title="City">
              <YMaps enterprise query={{ apikey: '6f09d219-95c2-4de5-af8a-b4f6356c216e' }}>
                
              <Map width="100%" height="100vh" defaultState={{  center: centerp, zoom: 12}}>
                {/* <Map width="100%" height="60vh" defaultState={{ center: [55.751574, 37.573856], zoom: 9 }}> */}
                  {points.map((coordinate, i) =>
                    <Placemark key={i}
                      geometry={coordinate.pos}
                      // onClick={onPlacemarkClick(coordinate)}
                      options={{
                        preset: getColorTaskOperator(coordinate.count_task, coordinate.correct_count, getColorCategory(coordinate.category), coordinate.line_id)
                      }}
                      // preset = 'islands#blueIcon'
                      properties={{
                        iconContent: 0,
                        hintContent: coordinate.category,
                        balloonContent: '№ ' + coordinate.line_id,
                        preset: 'islands#redSportIcon',
                      }}
                      modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                    />)}
                    <RulerControl />
                </Map>
              </YMaps>
      </Page>
    );
  }