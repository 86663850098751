import React, { useState, useEffect, createContext, useContext } from 'react';
import {
  Card, Stack, Box, Container,
  Typography, Button, Grid, Paper, listClasses, ListItem, List, Link
} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { ApiServer } from ".././settings"
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../components/Iconify';
import SearcherFragment from '../components/searcher_components/SearcherFragment';
// components
import { YMaps, Map, GeoObject, Placemark, ZoomControl, RulerControl } from "react-yandex-maps";
import Page from '../components/Page';
import { faker } from '@faker-js/faker';

import getDataJson from 'src/components/common_components/getDataJson';
import InputWithButton from 'src/components/common_components/inputWithButton';
import FileInfo from 'src/components/control/FileInfo';
import { countBy } from 'lodash';
import { verifyArr } from 'src/components/control/FileInfo';

export default function Video() {

  const [info, setInfo] = useState({ 'file': [], 'line': [] });
  //  const info = (videofilesInfo.length > 0) ? videofilesInfo[0] : [];
  console.log("info=", info);

  const show = (str) => { console.log(str) };

  // function checkStatus(filesList) { // менять статус задания на выполнено/не выполнено можно только если есть 4 хороших файла (verify 1)
  //   const succeessFiles = filesList.filter(file => file.verify == 1);
  //   console.log("succeessFiles=", succeessFiles);
  //   return (succeessFiles.length < 4);
  // }

  const reloadInfo = (str) => {
    const url = ApiServer.api + "/api/users_files.php?act=InfoById&videofiles_id=" + info.file.videofiles_id;
    getDataJson(url, setInfo);
  };


  return (
    <Page title="Searcher">
      <Container>
        <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Проверить видеофайл
          </Typography>

        </Stack>
        <InputWithButton inputFunction={
          (e) => {
            console.log(e);
            e = encodeURI(e);
            console.log(e);

            const url = ApiServer.api + "/api/users_files.php?act=InfoById&videofiles_id=" + e;

            console.log("search url");
            console.log(url);
            getDataJson(url, setInfo);
            //  const pic = info.line.img + "~" +  info.file.xg + "," + info.file.yg + "pmwtm3";
            //  console.log(pic);
          }}
          inputString="ввести videofiles_id ..."
          buttonString="Посмотреть"
        />

        {/* <Container> */}
          <Typography variant="h5" gutterBottom sx={{mt: 5}}>
            Видеофайл {info.file.videofiles_id} <br />
            {verifyArr[info.file.verify]}
          </Typography>
        {/* </Container> */}

        <Typography variant="body1" gutterBottom>
          Имя файла: {info.file.filename} <br />
          Номер точки: {info.file.line_id} <br />
          Категория точки: {info.line.category} <br />
          Начало съемки: {info.file.create_date} <br />
          Длина съемки: {(info.file.time_length / 60).toFixed(2)} минут <br />
          <br />
          Список ошибок: {info.file.files_status}
          <br />
          <br />
          1,2 - концы отрезка. 3 - точка съемки


          {/* <Link href={t.url}>
            Ссылка на координаты отрезка на Google картах
            </Link> */}
        </Typography>

        <img src={info.line.img + "~" + info.file.yg + "," + info.file.xg + ",pmwtm3"} />

        <Stack direction="row" spacing={2}> 
        <Button 
        variant="contained"
        color="error"
        onClick={
          () => {
            console.log('file ok');
            const url = ApiServer.api + "/api/users_files.php?act=setFileStatus&verify=1&videofiles_id=" + info.file.videofiles_id;
            getDataJson(url, reloadInfo);
          }
        }
        >
          Отметить как корректный
        </Button>
        <Button 
        variant="contained"
        color="error"onClick={
          () => {
            console.log('file error');
            const url = ApiServer.api + "/api/users_files.php?act=setFileStatus&verify=2&videofiles_id=" + info.file.videofiles_id;
            getDataJson(url, reloadInfo);
          }
        }

        >
          Отметить как некорректный
        </Button>
        </Stack>

      </Container>
    </Page>
  );
}