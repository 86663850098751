import React, {useState, useEffect, createContext, useContext} from 'react';
import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, listClasses, ListItem, List, Link
} from '@mui/material';
import {experimentalStyled as styled} from '@mui/material/styles';
import {ApiServer} from ".././settings"
import {Link as RouterLink} from 'react-router-dom';
import Iconify from '../components/Iconify';
import SearcherFragment from '../components/searcher_components/SearcherFragment';
// components
import {YMaps, Map, GeoObject, Placemark, ZoomControl, RulerControl} from "react-yandex-maps";
import Page from '../components/Page';
import {faker} from '@faker-js/faker';

import getDataJson from 'src/components/common_components/getDataJson';
import InputWithButton from 'src/components/common_components/inputWithButton';
import {countBy} from 'lodash';
import {Context} from 'src/Context';
import FileInfo from 'src/components/control/FileInfo';

export const show = (str) => {
    console.log(str)
};

export function getVideofileById(videofiles_id) {
    const url = ApiServer.api + "/api/api_videofile.php" + `?videofiles_id=${videofiles_id}` + "&act=control";
    return url;
}

export default function FileControl() {
    const [fileInfo, setFileInfo] = useState({});
    const [isShown, setIsShown] = useState(false)


    console.log('CITY=', Context.city);

    return (
        <Page title="Searcher">
            <Container>
                <Typography variant="h4" gutterBottom>
                    Проверить видеофайл
                </Typography>


                <Typography variant="h7" gutterBottom>
                    Зрительная проверка кадров видео
                </Typography>

                <Stack direction="row" alignItems="left" justifyContent="space-between" mb={3}>


                    <InputWithButton inputFunction={
                        (e) => {
                            console.log(e);
                            e = encodeURI(e);
                            console.log(e);

                            const url = getVideofileById(e);
                            console.log("search url");
                            console.log(url);
                            getDataJson(url, setFileInfo).then(r => setIsShown(true));
                        }
                    }
                                     inputString="ввести номер файла"
                                     buttonString="Загрузить"
                    />


                    <Button
                        variant="contained"
                        color="error"
                        onClick={
                            () => {
                                console.log('click random');
                                const url = ApiServer.api + "/api/api_videofile.php?act=control&random=random&city=" + Context.city;
                                getDataJson(url, setFileInfo);
                                setIsShown(true);
                            }
                        }>
                        Проверить случайный
                    </Button>
                    <div></div>
                </Stack>

                <Container>

                </Container>

                {isShown && (<div>
                    <Container>
                        <FileInfo key={faker.datatype.uuid()} videofiles_id={fileInfo.videofiles_id}
                                  verify={fileInfo.verify}
                                  line_id={fileInfo.line_id} info={fileInfo.info} create_date={fileInfo.create_date}
                                  time_length={fileInfo.time_length}
                                  start_converted_time={fileInfo.start_converted_time}
                                  files_status={fileInfo.files_status} exported_filepath={fileInfo.exported_filepath}
                                  line_category={fileInfo.line_category} time_mon_start={fileInfo.time_mon_start}
                                  time_mon_stop={fileInfo.time_mon_stop} time_evn_start={fileInfo.time_evn_start}
                                  time_evn_stop={fileInfo.time_evn_stop}
                        />
                    </Container>

                    <Box mt={3}>
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={
                                    () => {
                                        console.log('task ok');
                                        const url = ApiServer.api + "/api/api_videofile.php?act=set_eyechecked&eyechecked=1&videofiles_id=" + fileInfo.videofiles_id;
                                        getDataJson(url, show);
                                    }
                                }>
                                Отметить как правильный
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={
                                    () => {
                                        console.log('task error');
                                        const url = ApiServer.api + "/api/api_videofile.php?act=set_eyechecked&eyechecked=0&videofiles_id=" + fileInfo.videofiles_id;
                                        getDataJson(url, show);
                                    }
                                }>
                                Отметить как неправильный
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={
                                    () => {
                                        console.log('task error');
                                        const url = ApiServer.api + "/api/api_videofile.php?act=set_eyechecked&eyechecked=2&videofiles_id=" + fileInfo.videofiles_id;
                                        getDataJson(url, show);
                                    }
                                }


                            >
                                Переобрезать вручную
                            </Button>
                        </Stack>
                    </Box>
                </div>)}
            </Container>
        </Page>
    );
}