import {Box} from '@mui/material';
import {Edit as EditIcon, Delete as DeleteIcon} from '@mui/icons-material';
import {ApiServer} from ".././settings"
import MaterialReactTable from 'material-react-table';
import React, {useState, useEffect, createContext, useContext, useMemo} from 'react';
import Page from '../components/Page';
import getDataJson from 'src/components/common_components/getDataJson';
import {Context, FormatDate} from "src/Context";
import LineDialog from 'src/components/report/LineDialog';
import VerifyDialogue from "../components/bad_videos/VerifyDialogue";

const TableLines = (props) => {
    const columns = useMemo(
        () => [
            {
                accessorKey: 'videofiles_id',
                header: 'Номер видеофайла'
            },
            {
                accessorKey: 'verification_status',
                header: 'Статус неверифицированного файла'
            },
            {
                accessorKey: 'verification_comment',
                header: 'Комментарий для/от службы поддержки'
            },
            {
                accessorKey: 'filename',
                header: 'Путь файла'
            },
            {
                accessorKey: 'line_id',
                header: 'Номер точки'
            },
            {
                accessorKey: 'create_date',
                header: 'Дата и время создания видеофайла'
            },
            {
                accessorKey: 'files_status',
                header: 'Текст ошибки'
            },
        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={props.data}
            enableRowActions={true}
            enablePinning={false}
            enableColumnFilters={true}
            enablePagination={false}
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-child(2n+1)': {
                        backgroundColor: 'silver'
                    }
                })
            }}

            muiTableBodyRowProps={{
                sx: {
                    height: '10px',

                }
            }} muiTableBodyCellProps={{
            sx: {
                p: '2px 16px'
            }
        }}

            renderRowActions={({row}) => (
                <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                    <EditIcon
                        onClick={() => {
                            console.log("renderRowActions", row.original.videofiles_id);
                            props.setCurrentVideo(row.original.videofiles_id);
                            props.setOpen(true);
                        }}
                    />
                </Box>
            )}
        />
    );
}


export default function BadVideos() {
    const [videoLine, setVideoLine] = useState([]);
    const [currentVideo, setCurrentVideo] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const city = Context.city;
        const url = ApiServer.api + '/api/api_videofile.php?act=bad_videos&city=' + city;
        getDataJson(url, setVideoLine);
    }, []);

    return (
        <Page title="Bad videos">
            <TableLines data={videoLine} setCurrentVideo={setCurrentVideo} setOpen={setOpen}/>
            <VerifyDialogue onClose={() => setOpen(false)} open={open} videofiles_id={currentVideo} setVideoLine={setVideoLine}/>
        </Page>
    );
}

