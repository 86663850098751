import * as React from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DatePicker , { registerLocale }  from "react-datepicker";
import { Context, FormatDate } from "src/Context";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";

export default function BasicDateRangePicker(props) {
  //  const [value, setValue] = React.useState(null);
  const [startDate, setStartDate] = React.useState(new Date());
  React.useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);

  const isWeekday = (date) => {
    // console.log("date",date.getDay());
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
       <DatePicker
      selected={startDate}
      onChange={(date) => {
          console.log("date=", date);
          setStartDate(date);
        //  setContext(date);
          props.data[props.field]=FormatDate(date);
          props.onSelect();
      }
      }
      locale="en-GB"
      dateFormat = "yyyy-MM-dd"
      filterDate={isWeekday}
      placeholderText="Weeks start on Monday"
      
    />
    </LocalizationProvider>
  );
}