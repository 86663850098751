import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import getDataJson from '../common_components/getDataJson';
import { ApiServer } from "../.././settings";
import {
    Grid
} from '@mui/material';
import { ConstructionOutlined } from '@mui/icons-material';

// export interface SimpleDialogProps {
//     open: boolean;
//     onClose: () => void;
//     //  onClose: (value: string) => void;
// }

export function getUrlUserDateTasks(users_id, task_date) {
    return ApiServer.api + '/api/userdate_tasks.php?users_id=' + users_id + "&task_date=" + task_date;
}

function getUrlDeleteUserLineDateTask(users_id, task_date, line_id) {
    return ApiServer.api + '/api/userdate_tasks.php?users_id=' + users_id + "&task_date=" + task_date + "&line_id=" + line_id + "&delete=del";
}

function getUrlEnableUserLineDateTask(users_id, task_date, line_id, $enable) {
    return ApiServer.api + '/api/userdate_tasks.php?users_id=' + users_id + "&task_date=" + task_date + "&line_id=" + line_id + "&enable=" + $enable;
}

const TableUserDateTasks = (props) => {
    //should be memoized or stable
    const columns = useMemo(
        () => [
            {
                accessorKey: 'task_title',
                header: 'Номер задания',
            },
            {
                accessorKey: 'time',
                header: 'Время съемки',
            },
            {
                accessorKey: 'num', //access nested data with dot notation
                header: 'Номер на схеме',
            },
            // {
            //     accessorKey: 'task_status', //access nested data with dot notation
            //     header: 'Статус задания',
            // },
            // {
            //     accessorKey: 'street',
            //     header: 'Название улицы',
            // },

            // {
            //   accessorKey: 'users_id', //normal accessorKey
            //   header: '№',
            // },
        ],
        [],
    );

        function checkDate(date) {
            let res = false;
            const now = new Date();
            //  console.log("now=", now);
            //  console.log("date=", date);
            if (date) {
                const taskDate = new Date(date);
                //  console.log("taskDate=", taskDate);
                if (now - taskDate > 24*3600*1000) {
                    res = true;
                };
            };
            return res;
        }

    return (
        <Grid container>
            <Grid item xs={8}>
                <MaterialReactTable
                    columns={columns}
                    data={props.data.points}
                    enableRowActions
                    // enableStickyFooter 
                    enablePinning={false}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}

                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableSorting={false}


                    renderRowActions={({ row }) => (
                        <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5rem' }}>
                            <Button variant="contained"
                                color="primary"
                                onClick={() => {
                                    console.info('Delete', row.original.line_id);
                                    props.deleteLineDateTask(row.original.line_id);

                                }}
                                disabled = {checkDate(props.task_date)}
                            >
                                Удалить
                            </Button>
                            {/* <Button variant="contained"
                                color="primary"
                                onClick={() => {
                                    console.info('Enable', row.original.line_id);
                                    props.enableLineDateTask(row.original.line_id, "en");

                                }}
                                disabled = {checkDate(props.task_date)}
                            >
                                Enable
                            </Button>
                            <Button variant="contained"
                                color="primary"
                                onClick={() => {
                                    console.info('Disable', row.original.line_id);
                                    props.enableLineDateTask(row.original.line_id, "dis");

                                }}
                                disabled = {checkDate(props.task_date)}
                            >
                                Disable
                            </Button> */}

                        </div>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <img src={props.data.img} />
            </Grid>
        </Grid>
    );
};

function SimpleDialog(props) {
    //  const { onClose, selectedValue, open } = props;
    const { onClose, open, task_date, users_id } = props;

    const handleClose = () => {
        //  onClose(selectedValue);
        onClose();
        props.refreshMap(props.usersPointsMode );
    };

    // const handleListItemClick = (value: string) => {
    //     onClose(value);
    // };

    const [data, setData] = useState([]);

    useEffect(() => {
        console.log("SimpleDialog", props);
        getDataJson(getUrlUserDateTasks(props.users_id, props.task_date) + "&dialog=dial", setData);
        // props.users_id  
    }, [open]);

    const deleteLineDateTask = (line_id) => {
        getDataJson(getUrlDeleteUserLineDateTask(props.users_id, props.task_date, line_id) + "&dialog=dial", setData);
    }

    const enableLineDateTask = (line_id, enable) => {
        getDataJson(getUrlEnableUserLineDateTask(props.users_id, props.task_date, line_id, enable) + "&dialog=dial", setData);
        //  console.log(enable);
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={false}>
            <DialogTitle>{props.task_date}</DialogTitle>
            <TableUserDateTasks data={data} deleteLineDateTask={deleteLineDateTask} enableLineDateTask={enableLineDateTask} task_date={task_date}/>
        </Dialog>
    );
}

export default function TaskDialog(props) {
    const [open, setOpen] = React.useState(false);
    //  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    //  const handleClose = (value: string) => {
    const handleClose = () => {
        setOpen(false);
        //  setSelectedValue(value);
    };

    return (
        <div>
            <Button variant="contained"
                color="primary"
                onClick={() => {
                    //  console.info('View task_date', row.original.task_date);
                    //  view(row.original.task_date);
                    handleClickOpen()
                }}
            >
                Просмотр
            </Button>
            <SimpleDialog
                //  selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                task_date={props.task_date}
                users_id={props.users_id}
                refreshMap={props.refreshMap}
                usersPointsMode={props.usersPointsMode}
            />
        </div>
    );
}