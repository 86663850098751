import React, { useState } from "react";
// material
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// component

import SearchStyle from "./searchStyle";

export default function InputWithButton( { inputFunction = f => f, inputString = "input product ...", buttonString = "INPUT", InputStyle = SearchStyle}) {
    const [inputText, setInputText] = useState(""); 
    const submit = e => {
        e.preventDefault();
        inputFunction(inputText);
        setInputText("");
    };
    
    return (
        <form onSubmit={submit}>
            <InputStyle
                value={inputText}
                onChange={event => setInputText(event.target.value)}
                type="text"
                placeholder={inputString}
                required
            />
        <Button onClick={submit} variant="contained" size="medium">
          {buttonString}
        </Button>
        </form>
    );
} 