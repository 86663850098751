import {
    Card, Stack, Box, Container,
    Typography, Button, Grid, Paper, TextField
  } from '@mui/material';
  
  import { ApiServer } from ".././settings"
  // components
  import MaterialReactTable from 'material-react-table';
  import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
  import { YMaps, Map, GeoObject, Placemark, RulerControl } from "react-yandex-maps";
  import Page from '../components/Page';
  
  import getDataJson from 'src/components/common_components/getDataJson';
  
  import { getUrlLineTaskSum } from 'src/components/table_users/TableUsersLineTaskSum';
  import { getUrlUserDateTasks } from 'src/components/table_users/TaskDialog';
  import { getColorCategory, getColorTask } from './MapYandex';
  import TaskView from 'src/components/searcher_components/taskView';
  import { getUrlCity } from './MapYandex';
  import { getURLVarArr } from './User';
  import VisibilityIcon from '@mui/icons-material/Visibility';

import { useDateRangePickerDefaultizedProps } from '@mui/x-date-pickers-pro/DateRangePicker/shared';
import CityProgressTable from 'src/components/report/CityProgressTable';
import AllFilesTable from 'src/components/report/AllFilesTable';

  
  export default function Allcityfiles() {

    const varArr = getURLVarArr();
  console.log("varArr=", varArr);
  const getCity = decodeURI(getURLVarArr().city);
  console.log("GET CITY FROM URL=", getCity);

    return (
        // <Page title="Progress">
            // <Container>
              <Grid container spacing={4} sx={{pl:2, pr:2}}>
                    <AllFilesTable city={getCity}/>
                </Grid>
            // </Container>
      // </Page>
    );
  }