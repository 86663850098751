import {styled} from '@mui/material/styles';
import {OutlinedInput} from '@mui/material';

const SearchStyle = styled(OutlinedInput)(({theme}) => ({
    width: 200,
    height: 40,
    marginRight: 10,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

export const CommentStyle = styled(OutlinedInput)(({theme}) => ({
    width: 930,
    height: 40,
    marginRight: 10,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

export const SupportStyle = styled(OutlinedInput)(({theme}) => ({
    width: 775,
    height: 40,
    marginRight: 10,
    marginLeft: 15,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

export default SearchStyle;