import {Box} from '@mui/material';
import {Edit as EditIcon} from '@mui/icons-material';
import {ApiServer} from "../settings"
import MaterialReactTable from 'material-react-table';
import React, {useState, useEffect, useMemo} from 'react';
import Page from '../components/Page';
import getDataJson from 'src/components/common_components/getDataJson';
import {Context} from "src/Context";
import LineDialog from 'src/components/report/LineDialog';

const TableLines = (props) => {

    const columns = useMemo(
        () => [
            {
                accessorKey: 'line_id', //access nested data with dot notation
                header: 'Номер отрезка',
                //    size: 10,
            },
            {
                accessorKey: 'total_count',
                header: 'Файлов отправлено заказчику',
                //   size: 10,
            },
            {
                accessorKey: 'count_morning',
                header: 'Отправлено утренних (принято + не проверено заказчиком)',
                //   size: 10,
            },
            {
                accessorKey: 'count_evening',
                header: 'Отправлено вечерних (принято + не проверено заказчиком)',
                //   size: 10,
            },
            {
                accessorKey: 'create_date',
                header: 'Дата последней загрузки',
                //   size: 10,
            },
            {
                accessorKey: 'count_task',
                header: 'Открытых заданий',
                //   size: 10,
            },
            {
                accessorKey: 'count_morning_accepted',
                header: 'Принято заказчиком утренних',
                //   size: 10,
            },
            {
                accessorKey: 'count_evening_accepted',
                header: 'Принято заказчиком вечерних',
                //   size: 10,
            },
            {
                accessorKey: 'accepted',
                header: 'Принято заказчиком',
                //   size: 10,
            },
            {
                accessorKey: 'paid',
                header: 'Оплачено',
                //   size: 10,
            },

        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={props.data}
            enableRowActions={true}
            enablePinning={false}
            enableColumnFilters={true}
            enablePagination={false}
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-child(2n+1)': {
                        backgroundColor: 'silver'
                    }
                })
            }}

            muiTableBodyRowProps={{
                sx: {
                    height: '10px',

                }
            }} muiTableBodyCellProps={{
            sx: {
                p: '2px 16px'
            }
        }}

            renderRowActions={({row}) => (
                <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                    <EditIcon
                        onClick={() => {
                            console.log("renderRowActions", row.original.line_id);
                            props.setCurrentLine(row.original.line_id);
                            props.setOpen(true);
                        }}
                    />
                </Box>
            )}
        />
    );
}


export default function Reportnew() {
    const [statLine, setStatLine] = useState([]);

    const [currentLine, setCurrentLine] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const city = Context.city;
        const url = ApiServer.api + '/api/report.php?title=linestats&city=' + city;
        getDataJson(url, setStatLine);
    }, []);

    return (
        <Page title="Statistics">
            <TableLines data={statLine} setCurrentLine={setCurrentLine} setOpen={setOpen}/>
            <LineDialog onClose={() => setOpen(false)} open={open} line_id={currentLine} setStatLine={setStatLine}/>
        </Page>
    );
}

