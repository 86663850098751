import {
    Card,
    Stack,
    Container,
    Typography
  } from '@mui/material';
  
  import SearcherFragment from '../components/searcher_components/SearcherFragment';
  // components
  
  import Page from '../components/Page';
  
  export default function Search() {
    return (
      <Page title="Searcher">
        <Container>
          <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Посмотреть задание
            </Typography>
  
            <Typography variant="h7" gutterBottom>
              По номеру task_title
            </Typography>
          </Stack>
  
          <Card>
            <SearcherFragment/>
          </Card>
        </Container>
      </Page>
    );
  }