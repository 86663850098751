// material
import { alpha, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { GlobalStyles, Button, TableContainer, Table, Typography,
TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { DataArrayTwoTone, PropaneSharp } from '@mui/icons-material';
import { array } from 'prop-types';
import getDataJson from '../common_components/getDataJson';
import { ApiServer } from "../.././settings"
import { Context } from 'src/Context';
import TaskDialog from './TaskDialog';


// ----------------------------------------------------------------------
export function getUrlLineTaskSum(users_id) {
  return ApiServer.api + '/api/users_task.php?users_id=' + users_id;
}

function getUrlDeleteLineTaskSum(users_id, task_date) {
  return ApiServer.api + '/api/users_task.php?users_id=' + users_id + '&task_date=' + task_date + '&delete=del';
}
 

const TableUsersLineTaskSumStart = (props) => {

  //console.log('TableUsersLineTaskSumStart', props )
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'task_date', //access nested data with dot notation
        header: 'Дата',
      },
      {
        accessorKey: 'count_line',
        header: 'Кол-во',
      },
      // {
      //   accessorKey: 'users_id', //normal accessorKey
      //   header: '№',
      // },
    ],
    [],
  );

  return <MaterialReactTable 
          columns={columns} 
          data={props.data} 
          enableRowActions
          // enableStickyFooter 
          enablePinning={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}

          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting={false}


          renderRowActions={({ row }) => (
            <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5rem' }}>       
            <Button          variant="contained"
                 color="primary"
                 onClick={() => {
                   console.info('Delete', row.original.task_date);
                   props.deleteUserTask(row.original.task_date);
                 }}
               >
                Удалить
               </Button>

                 <TaskDialog task_date={row.original.task_date} users_id={props.users_id} refreshMap={props.refreshMap} usersPointsMode={props.usersPointsMode}/>
               {/* <Button          variant="contained"
                 color="primary"
                 onClick={() => {
                   console.info('View task_date', row.original.task_date);
                   view(row.original.task_date);
                 }}
               >
                View
               </Button> */}

             </div>
           )}
    />;
};


export default function TableUsersLineTaskSum(props) {
  const theme = useTheme();

  const navigate = useNavigate();

  const background = {
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72)
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("TableUsersLineTaskSum", props);
    getDataJson(getUrlLineTaskSum(props.users_id), setData);
    // props.users_id  
  }, [props.users_id]);

  const deleteUserTask = (task_date) => {
    getDataJson(getUrlDeleteLineTaskSum(props.users_id, task_date), setData);
    props.refreshMap(props.usersPointsMode);
  }

  //  getDataJson(getUrlDeleteLineTaskSum(props.users_id), setData);

  return <div>
    <Typography variant="h6">
      Задания для {props.users_title}
    </Typography>
    
    {/* <Button          variant="contained"
                 color="primary"
                 onClick={() => {
                  //  console.info('TableTask', props.data);
                   // props.data.map((item) => {
                    data.map((item) => {
                        console.info('item', item);
                        // Удалить задание
                   })
                  //  props.view(row.original.users_id);
                 }}
               >
                Удалить все задания
                getDataJson(getUrlDeleteLineTaskSum(props.users_id), setData);
               </Button> */}
    <Button  variant="contained"
              color="primary"
              onClick={() => {
                //  navigate('/user?users_id='+ props.users_id, { replace: true });
                  navigate('/user?users_id='+ props.users_id, { replace: true });
              }}
    >
      Перейти на страницу исполнителя
    </Button>

    <TableUsersLineTaskSumStart data={data} deleteUserTask={deleteUserTask} users_id={props.users_id} refreshMap={props.refreshMap} usersPointsMode={props.usersPointsMode}/>
  </div>
  // return <div>TableUsers <BasicDateRangePicker/></div>
}
